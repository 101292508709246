.tag {
	background-color: var(--color-green-alpha-0);
	border: 1px solid;
	border-radius: 1.25rem;
	box-sizing: content-box;
	color: var(--color-black);
	display: inline-block;
	font-family: var(--font-sans);
	font-size: 0.9375rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: var(--font-weight-regular);
	letter-spacing: normal;
	line-height: 1.5;
	outline: 0;
	padding: 0.1875rem 1rem;
	position: relative;
	text-align: left;
	text-decoration: none;
	transition:
		background-color 96ms linear,
		border-color 96ms linear,
		color 96ms linear;
}

@media (min-width: 1280px) {
	.tag {
		font-size: 1.125rem;
		line-height: 1.56;
		padding: 0.3125rem 1.25rem;
	}
}

.theme-black .tag {
	color: var(--color-white);
}

/* Disabled state. */
.tag[disabled] {
	color: var(--color-black-alpha-30);
	cursor: not-allowed;
}

.theme-black .tag[disabled] {
	color: var(--color-white-alpha-30);
}

/* Focus style. */
.tag:not([disabled]):focus {
	border-color: var(--color-green);
}

/* Hover style. */
.tag:not([disabled]):hover {
	background-color: var(--color-green);
	border-color: var(--color-green);
	color: var(--color-black);
}

/* Selected state. */
.tag[selected] {
	background-color: var(--color-green);
	border-color: var(--color-green);
	color: var(--color-black);
	padding-right: 2.5rem;
	position: relative;
}

.tag[selected] .tag__icon {
	display: block;
	position: absolute;
	top: 0.1875rem;
	right: 0.25rem;
	width: 1.375rem;
	height: 1.375rem;
}

.tag[selected] .tag__icon__border {
	fill: var(--color-black);
	transition: fill 96ms linear;
}

.tag[selected] .tag__icon__background {
	fill: var(--color-black);
	transition: fill 96ms linear;
}

.tag[selected] .tag__icon__cross {
	fill: var(--color-white);
	transition: fill 96ms linear;
}

.tag[selected]:focus .tag__icon__background,
.tag[selected]:hover .tag__icon__background {
	fill: var(--color-black-alpha-0);
}

.tag[selected]:focus .tag__icon__cross,
.tag[selected]:hover .tag__icon__cross {
	fill: var(--color-black);
}

@media (min-width: 1280px) {
	.tag[selected] {
		padding-right: 3rem;
	}

	.tag[selected] .tag__icon {
		top: 0.4375rem;
		right: 0.5625rem;
		width: 1.5rem;
		height: 1.5rem;
	}
}
