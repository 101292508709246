.wrapper {
	box-sizing: border-box;
	padding-right: var(--wrapper-spacing);
	padding-left: var(--wrapper-spacing);
	width: 100%;
}

@media (min-width: 768px) {
	.wrapper {
		padding-right: var(--wrapper-spacing-from-small);
		padding-left: var(--wrapper-spacing-from-small);
	}
}

@media (min-width: 1280px) {
	.wrapper {
		padding-right: var(--wrapper-spacing-from-wide);
		padding-left: var(--wrapper-spacing-from-wide);
	}
}

@media (min-width: 1440px) {
	.wrapper {
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0;
		width: var(--wrapper-max-width);
	}
}
