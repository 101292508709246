.image {
	display: block;
	margin: 0;
	padding: 0;
	position: relative;
	width: 100%;
}

/* #region Image container. */
.image__container {
	padding-bottom: calc(100% / 5 * 3); /* Default 5:3 aspect ratio. */
	position: relative;
}

.image[data-aspect-ratio="1:1"] .image__container {
	padding-bottom: calc(100% / 1 * 1);
}

.image[data-aspect-ratio="2:1"] .image__container {
	padding-bottom: calc(100% / 2 * 1);
}

.image[data-aspect-ratio="2:3"] .image__container {
	padding-bottom: calc(100% / 2 * 3);
}

.image[data-aspect-ratio="3:2"] .image__container {
	padding-bottom: calc(100% / 3 * 2);
}

.image[data-aspect-ratio="3:4"] .image__container {
	padding-bottom: calc(100% / 3 * 4);
}

.image[data-aspect-ratio="4:3"] .image__container {
	padding-bottom: calc(100% / 4 * 3);
}

.image[data-aspect-ratio="5:3"] .image__container {
	padding-bottom: calc(100% / 5 * 3); /* Again, explicitly, should default change. */
}

.image[data-aspect-ratio="16:9"] .image__container {
	padding-bottom: calc(100% / 16 * 9);
}

.image[data-aspect-ratio="21:9"] .image__container {
	padding-bottom: calc(100% / 21 * 9);
}
/* #endregion */

/* #region Image. */
.image__asset {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
}
/* #endregion */

/* #region Label link. */
.image__label-container {
	position: absolute;
	right: var(--space-16);
	bottom: var(--space-16);
	max-width: calc(100% - (var(--space-16) * 2));
	z-index: 2;
}

@media (min-width: 1024px) {
	.image__label-container {
		right: var(--space-24);
		bottom: var(--space-24);
		max-width: calc(100% - (var(--space-24) * 2));
	}
}

.carousel .image__label-container {
	max-width: calc(100% - (var(--space-16) * 2) - 5rem); /* Leave extra room for carousel controls. */
}

@media (min-width: 1024px) {
	.carousel .image__label-container {
		max-width: calc(100% - (var(--space-24) * 2) - 7.5rem); /* Leave extra room for carousel controls. */
	}
}

@media (min-width: 1280px) {
	.carousel .image__label-container {
		max-width: calc(100% - (var(--space-24) * 2) - 10rem); /* Leave extra room for carousel controls. */
	}
}
/* #endregion */

/* #region Caption. */
.image__caption {
	display: block;
	margin: 0.625rem 0 0;
	margin-top: 0.625rem;
	padding: 0;
	width: 100%;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.image__caption {
		margin-top: var(--space-16);
	}
}
/* #endregion */
