.post-meta {
}

/* #region Title */
.post-meta__title {
	color: var(--color-foreground-alpha-70);
	margin-top: 0;
	margin-bottom: 0;
}
/* #endregion */
