.pagination {
	margin-top: var(--space-16);
	margin-bottom: var(--space-16);
	width: 100%;
}

@media (min-width: 1024px) {
	.pagination {
		margin-top: var(--space-24);
		margin-bottom: var(--space-24);
	}
}

@media (min-width: 1024px) {
	.pagination {
		margin-top: var(--space-40);
		margin-bottom: var(--space-40);
	}
}

/* Row. */
.pagination__row {
	display: flex;
	padding: 0;
	justify-content: space-between;
}

/* Previous + next + current. */
.pagination__previous,
.pagination__current,
.pagination__next {
	display: block;
}

.pagination__previous,
.pagination__next {
	flex-basis: 50%;
}

.pagination__current {
	flex-basis: 4.5rem;
	flex-shrink: 1;
	text-align: center;
}

.pagination__next {
	text-align: right;
}

.pagination__link--go-to-last {
	color: var(--color-foreground-alpha-55);
}

.pagination__link--go-to-last:focus,
.pagination__link--go-to-last:hover {
	background-color: var(--color-highlight);
	color: var(--color-foreground);
	text-decoration: none;
}
