/**
 * Row: a row to place columns in.
 * Assumed to be used inside a wrapper!
 */
.row {
	position: relative;
	width: 100%;
}

@media (min-width: 1024px) {
	.row {
		box-sizing: border-box;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	}
}
