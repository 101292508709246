.button {
	appearance: none;
	background: none;
	background-color: var(--color-green);
	border: 0;
	border-radius: var(--space-24);
	color: var(--color-black);
	display: inline-block;
	font-family: var(--font-sans);
	font-size: 1rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: var(--font-weight-regular);
	letter-spacing: normal;
	line-height: 1.5;
	outline: 0;
	padding: 0.5rem 1rem;
	position: relative;
	transition:
		background-color 96ms linear,
		color 96ms linear;
}

@media (min-width: 1280px) {
	.button {
		font-size: 1.125rem;
		line-height: 1.56;
		padding: 0.6875rem 1.25rem;
	}
}

/* Button is a link. */
a.button {
	text-decoration: none;
}

/* Button is an actual button. */
button.button {
	cursor: pointer;
}

/* Disabled state. */
.button[disabled] {
	background-color: var(--color-black-alpha-30);
	color: var(--color-white);
	cursor: not-allowed;
}

.theme-black .button[disabled] {
	background-color: var(--color-white-alpha-30);
	color: var(--color-black);
}

/* Focus style. */
.button:not([disabled]):focus::before {
	background: transparent;
	border: 2px solid var(--color-green);
	border-radius: 2.375rem;
	content: "";
	pointer-events: none;
	position: absolute;
	top: -5px;
	left: -5px;
	width: calc(100% + 6px);
	height: calc(100% + 6px);
}

/* Hover style. */
.button:not([disabled]):hover {
	background-color: var(--color-black);
	color: var(--color-white);
}

.theme-black .button:not([disabled]):hover {
	background-color: var(--color-white);
	color: var(--color-black);
}
