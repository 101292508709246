.wrapping-filter-list {
}

.wrapping-filter-list__list {
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(-1 * var(--space-16));
	margin-bottom: 0;
	padding: 0;
}

@media (min-width: 1280px) {
	.wrapping-filter-list__list {
		margin-top: calc(-1 * var(--space-24));
	}
}

.wrapping-filter-list__list__item {
	display: block;
	margin-top: var(--space-16);
}

.wrapping-filter-list__list__item:not(:last-child) {
	margin-right: var(--space-16);
}

@media (min-width: 1280px) {
	.wrapping-filter-list__list__item {
		margin-top: var(--space-24);
	}

	.wrapping-filter-list__list__item:not(:last-child) {
		margin-right: var(--space-24);
	}
}
