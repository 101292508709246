.info-dialog {
	box-sizing: border-box;
	content-visibility: hidden;
	display: none;
	overflow-y: auto;
	pointer-events: none;
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: var(--z-index-dialog);
}

@media (min-width: 1024px) {
	.info-dialog {
		width: 50%;
	}
}

.info-dialog[data-state="opening"],
.info-dialog[data-state="open"],
.info-dialog[data-state="closing"] {
	content-visibility: visible;
	display: block;
	pointer-events: auto;
}

.info-dialog:focus {
	outline: none;
}

/* #region Close. */
.info-dialog__close-button {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	color: var(--color-foreground);
	cursor: pointer;
	display: block;
	padding: 0;
	transition: color 96ms linear;
	width: 1.125rem;
	height: 1.125rem;
}

.info-dialog__close-button:focus,
.info-dialog__close-button:hover {
	color: var(--color-highlight);
}

.info-dialog__close-button[data-forced-focus] {
	outline: 0;
}

.info-dialog__close-button:focus:not(:focus-visible) {
	outline: 0;
}

.info-dialog__close-button .icon {
	display: block;
	width: 100%;
	height: 100%;
}

.info-dialog__close-container {
	background-color: var(--color-white);
	box-sizing: border-box;
	display: block;
	padding: 0.9375rem var(--wrapper-spacing);
	position: absolute;
	transform: translate3d(0, 0, 3px); /* this fixes z-index issues in safari */
	width: 100%;
	z-index: 3;
}

@media (min-width: 768px) {
	.info-dialog__close-container {
		padding-right: var(--wrapper-spacing-from-small);
		padding-left: var(--wrapper-spacing-from-small);
	}
}

@media (min-width: 1024px) {
	.info-dialog__close-container {
		padding-right: var(--space-24);
		padding-left: var(--space-24);
		width: auto;
	}
}

@media (min-width: 1280px) {
	.info-dialog__close-container {
		padding: 1.375rem 2rem;
	}
}
/* #endregion Close. */

/* #region Scroll container. */
.info-dialog__scroll-container {
	overflow-x: hidden;
	overflow-y: auto;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}
/* #endregion */

/* #region Inner. */
.info-dialog__inner {
	box-sizing: border-box;
	padding: var(--space-80) var(--wrapper-spacing);
	width: 100%;
	height: auto;
	min-height: 100%;
}

@media (min-width: 768px) {
	.info-dialog__inner {
		padding-right: var(--wrapper-spacing-from-small);
		padding-left: var(--wrapper-spacing-from-small);
	}
}

@media (min-width: 1024px) {
	.info-dialog__inner {
		padding: var(--space-136) var(--space-24) var(--space-136) var(--space-80);
	}
}

@media (min-width: 1280px) {
	.info-dialog__inner {
		padding-right: var(--space-98);
		padding-left: var(--space-98);
	}
}

/* from-xlarge */
@media (min-width: 1920px) {
	.info-dialog__inner {
		padding-right: calc(100% - var(--space-98) - 700px); /* Max-width of 700px. */
	}
}
/* #endregion */

/* #region Floating button. */
.info-dialog__floating-button {
	position: absolute;
	text-align: center;
	bottom: var(--space-24);
	left: 50%;
	transform: translate3d(-50%, 0, 2px); /* this fixes z-index issues in safari */
	z-index: 2;
}

/* from-xlarge */
@media (min-width: 1920px) {
	.info-dialog__floating-button {
		left: calc(var(--space-98) + 350px); /* Center with 700px-wide content. */
	}
}
/* #endregion */
