/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.l-site {
}

/* #region Header. */
.l-site__header-container {
	position: relative;
	position: sticky;
	top: 0;
	width: 100%;
	z-index: var(--z-index-site-header);
}

/* Older browsers get a non-sticky header to ensure everything is visible and readable. */
body[is-legacy="true"] .l-site__header-container {
	position: relative;
}

@media (min-width: 1280px) {
	.l-site__header-container {
		margin-top: calc(var(--site-header-height-from-wide-at-top) - var(--site-header-height-from-wide));
	}
}
/* #endregion */

/* #region Content. */
.l-site__content-container {
	position: relative;
	z-index: var(--z-index-site-content);
	/* margin bottom for logo - logo spacing from viewport bottom */
	margin-bottom: calc(var(--space-48) - var(--space-16));
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.l-site__content-container {
		/* margin bottom for logo - logo spacing from viewport bottom */
		margin-bottom: calc(var(--space-56) - var(--space-24));
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.l-site__content-container {
		/* margin bottom for logo - logo spacing from viewport bottom */
		margin-bottom: calc(var(--space-80) - var(--space-48));
	}
}

/* from-large */
@media (min-width: 1440px) {
	.l-site__content-container {
		/* margin bottom for logo - logo spacing from viewport bottom */
		margin-bottom: calc(var(--space-80) - var(--space-56));
	}
}

/* #endregion */

/* #region Sticky logo (parent for sticky positioning) */
.l-site__sticky-logo {
	display: block;
	margin-right: auto;
	margin-left: var(--wrapper-spacing);
	pointer-events: none;
	position: fixed; /* Fallback for browsers without sticky support. */
	position: sticky;
	bottom: 0;
	left: 0; /* Needed for the fallback. */
	width: var(--sticky-logo-size);
	height: auto;
	z-index: var(--z-index-sticky-logo);
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.l-site__sticky-logo {
		margin-left: var(--wrapper-spacing-from-small);
		width: var(--sticky-logo-size-from-small);
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.l-site__sticky-logo {
		margin-left: var(--wrapper-spacing-from-wide);
		width: var(--sticky-logo-size-from-wide);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.l-site__sticky-logo {
		margin-left: calc((100% - var(--wrapper-max-width)) / 2);
	}
}
/* #endregion */

/* #region Sticky logo link. */
.l-site__sticky-logo-link {
	display: block;
	pointer-events: auto;
	position: absolute;
	text-decoration: none;
	bottom: var(--space-16);
	left: 0;
	width: var(--sticky-logo-size);
	height: var(--sticky-logo-size);
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.l-site__sticky-logo-link {
		bottom: var(--space-24);
		width: var(--sticky-logo-size-from-small);
		height: var(--sticky-logo-size-from-small);
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.l-site__sticky-logo-link {
		bottom: var(--space-48);
		width: var(--sticky-logo-size-from-wide);
		height: var(--sticky-logo-size-from-wide);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.l-site__sticky-logo-link {
		bottom: var(--space-56);
	}
}

/* Icon styles. */
.l-site__sticky-logo .icon {
	display: block;
	width: 100%;
	height: 100%;
}

.l-site__sticky-logo .icon__background {
	fill: var(--color-highlight);
}

.l-site__sticky-logo .icon__letter {
	fill: var(--color-white);
}

.l-site__sticky-logo:focus .icon__background,
.l-site__sticky-logo:hover .icon__background {
	fill: var(--color-white);
}

.l-site__sticky-logo:focus .icon__letter,
.l-site__sticky-logo:hover .icon__letter {
	fill: var(--color-highlight);
}

.l-styleguide .l-site__sticky-logo {
	display: none;
}
/* #endregion */

/* #region Sticky logo parking. */
.l-site__sticky-logo-parking {
	/* logo sizes + logo spacing from viewport bottom - excess margin from last row */
	height: calc(var(--sticky-logo-size) + var(--space-16) - 0px);
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.l-site__sticky-logo-parking {
		/* logo sizes + logo spacing from viewport bottom - excess margin from last row */
		height: calc(var(--sticky-logo-size-from-small) + var(--space-24) - var(--space-8));
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.l-site__sticky-logo-parking {
		/* logo sizes + logo spacing from viewport bottom - excess margin from last row */
		height: calc(var(--sticky-logo-size-from-small) + var(--space-24) - var(--space-24));
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.l-site__sticky-logo-parking {
		/* logo sizes + logo spacing from viewport bottom - excess margin from last row */
		height: calc(var(--sticky-logo-size-from-wide) + var(--space-48) - 0px);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.l-site__sticky-logo-parking {
		/* logo sizes + logo spacing from viewport bottom - excess margin from last row */
		height: calc(var(--sticky-logo-size-from-wide) + var(--space-56) - var(--space-56));
	}
}

.section + .l-site__sticky-logo-parking {
	padding-top: 0;
}
/* #endregion */

/* #region marquee */
.l-site__marquee-container {
	padding-top: var(--space-48);
	padding-bottom: 0;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.l-site__marquee-container {
		padding-top: var(--space-56);
		padding-bottom: 0;
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.l-site__marquee-container {
		padding-top: var(--space-80);
		padding-bottom: 0;
	}
}
/* #endregion marquee */

/* #region Doormat. */
.l-site__doormat {
	padding-top: var(--space-48);
	padding-bottom: var(--space-48);
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.l-site__doormat {
		padding-top: var(--space-56);
		padding-bottom: var(--space-56);
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.l-site__doormat {
		padding-top: calc(var(--section-spacing-from-medium) - var(--space-24));
		padding-bottom: var(--section-spacing-from-medium);
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.l-site__doormat {
		padding-top: var(--space-80);
		padding-bottom: var(--space-80);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.l-site__doormat {
		padding-top: var(--space-80);
		padding-bottom: var(--space-80);
	}
}

/* from-small,tablet-p */
@media (min-width: 768px) and (max-width: 1023px) {
	.l-site__doormat .column:not(:last-child) {
		margin-bottom: var(--space-56);
	}

	.site-footer .column:not(:last-child) {
		margin-bottom: var(--space-56);
	}
}
/* #endregion */

/* Pages with no hero */
.l-site--without-hero.l-site--with-sub-navigation .l-site__content {
	padding-top: var(--site-header-height);
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.l-site--without-hero.l-site--with-sub-navigation .l-site__content {
		padding-top: var(--site-header-height-from-wide-at-top);
	}
}

/* up-to-medium */
@media (max-width: 1023px) {
	.l-site--without-hero.l-site--with-translations .l-site__content {
		padding-top: var(--site-header-height);
	}

	.l-site--without-hero.l-site--with-sub-navigation.l-site--with-translations .l-site__content {
		padding-top: calc(var(--site-header-height) * 2);
	}
}
