.theme-black {
	background-color: var(--color-black);
	color: var(--color-white);
}

.theme-green {
	background-color: var(--color-green);
	color: var(--color-black);
}

.theme-site {
	background-color: var(--color-background);
	color: var(--color-foreground);
}

.theme-white {
	background-color: var(--color-white);
	color: var(--color-black);
}
