.navigation-overlay {
}

.navigation-overlay--fallback {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Header. */
.navigation-overlay__header {
	display: flex;
	align-items: center;
	pointer-events: none;
	position: relative;
	height: var(--site-header-height);
	z-index: 2;
}

@media (min-width: 1280px) {
	.navigation-overlay__header {
		padding-top: var(--space-24);
		height: var(--site-header-height-from-wide);
	}
}

/* Sticky logo. */
.navigation-overlay__sticky-logo {
	pointer-events: auto;
	position: fixed;
	text-decoration: none;
	bottom: var(--space-16);
	left: var(--wrapper-spacing);
	width: var(--sticky-logo-size);
	height: var(--sticky-logo-size);
}

@media (min-width: 768px) {
	.navigation-overlay__sticky-logo {
		bottom: var(--space-24);
		left: var(--wrapper-spacing-from-small);
		width: var(--sticky-logo-size-from-small);
		height: var(--sticky-logo-size-from-small);
	}
}

@media (min-width: 1280px) {
	.navigation-overlay__sticky-logo {
		bottom: var(--space-48);
		left: var(--wrapper-spacing-from-wide);
		width: var(--sticky-logo-size-from-wide);
		height: var(--sticky-logo-size-from-wide);
	}
}

@media (min-width: 1440px) {
	.navigation-overlay__sticky-logo {
		bottom: var(--space-56);
		left: calc((100% - var(--wrapper-max-width)) / 2);
	}
}

.navigation-overlay__sticky-logo .icon {
	display: block;
	width: 100%;
	height: 100%;
}

.navigation-overlay__sticky-logo .icon__background {
	fill: var(--color-highlight);
}

.navigation-overlay__sticky-logo .icon__letter {
	fill: var(--color-black);
}

.navigation-overlay__sticky-logo:focus .icon__background,
.navigation-overlay__sticky-logo:hover .icon__background {
	fill: var(--color-white);
}

.navigation-overlay__sticky-logo:focus .icon__letter,
.navigation-overlay__sticky-logo:hover .icon__letter {
	fill: var(--color-highlight);
}

/* Translate. */
@media (max-width: 1023px) {
	.navigation-overlay__translate-container {
		display: none;
	}
}

@media (min-width: 1024px) {
	.navigation-overlay__translate-container {
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: var(--column-gap-from-medium);
		width: calc((var(--column-width-from-medium) * 4) + (var(--column-gap-from-medium) * 3));
	}
}

@media (min-width: 1280px) {
	.navigation-overlay__translate-container {
		margin-right: var(--column-gap-from-wide);
		width: calc((var(--column-width-from-wide) * 4) + (var(--column-gap-from-wide) * 3));
	}
}

/* Buttons. */
.navigation-overlay__buttons-container {
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	justify-content: space-between;
	align-items: center;
	width: auto;
}

/* Search link. */
.navigation-overlay__search-link {
	color: currentColor;
	display: inline-block;
	pointer-events: auto;
	transition: color 96ms linear;
	width: 20px;
	height: 20px;
}

.navigation-overlay__search-link:focus,
.navigation-overlay__search-link:hover {
	color: var(--color-white);
}

.navigation-overlay__search-link:focus:not(:focus-visible) {
	outline: 0;
}

.navigation-overlay__search-link:not(:last-child) {
	margin-right: var(--space-24);
}

.navigation-overlay__search-link .icon {
	display: block;
	width: 100%;
	height: 100%;
}

/* Close button. */
.navigation-overlay__close-button {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	color: currentColor;
	cursor: pointer;
	display: inline-block;
	padding: 0;
	pointer-events: auto;
	transform: translateY(2px); /* Visually center. */
	transition: color 96ms linear;
	width: 26px;
	height: 26px;
}

@media (min-width: 1280px) {
	.navigation-overlay__close-button {
		transform: translateY(3px); /* Visually center. */
	}
}

.navigation-overlay__close-button:focus,
.navigation-overlay__close-button:hover {
	color: var(--color-white);
}

.navigation-overlay__close-button[data-forced-focus] {
	outline: 0;
}

.navigation-overlay__close-button:focus:not(:focus-visible) {
	outline: 0;
}

.navigation-overlay__close-button .icon {
	display: block;
	width: 100%;
	height: 100%;
}

/* Scroll container. */
.navigation-overlay__scroll-container {
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: auto;
	padding-right: var(--wrapper-spacing);
	padding-left: var(--wrapper-spacing);
	position: fixed;
	top: var(--site-header-height);
	right: 0;
	width: 100%;
	height: calc(100% - var(--site-header-height));
	z-index: 1;
}

@media (min-width: 768px) {
	.navigation-overlay__scroll-container {
		padding-right: var(--wrapper-spacing-from-small);
		padding-left: var(--wrapper-spacing-from-small);
	}
}

@media (min-width: 1280px) {
	.navigation-overlay__scroll-container {
		padding-right: var(--wrapper-spacing-from-wide);
		padding-left: var(--wrapper-spacing-from-wide);
		top: 0;
		height: 100%;
	}
}

@media (min-width: 1440px) {
	.navigation-overlay__scroll-container {
		padding-right: calc((100% - var(--wrapper-max-width)) / 2);
		padding-left: calc((100% - var(--wrapper-max-width)) / 2);
	}
}

/* Inner. */
.navigation-overlay__inner {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding-bottom: var(--site-header-height);
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	height: auto;
	min-height: 100%;
}

@media (min-width: 768px) {
	.navigation-overlay__inner {
		margin-right: 0;
		margin-left: auto;
		width: calc(50% - (var(--column-gap-from-small) / 2));
	}
}

@media (min-width: 1024px) {
	.navigation-overlay__inner {
		width: calc(50% - (var(--column-gap-from-medium) / 2));
	}
}

@media (min-width: 1280px) {
	.navigation-overlay__inner {
		padding-bottom: var(--site-header-height-from-wide);
	}
}

/* Menus. */
.navigation-overlay__menus {
	margin-top: var(--space-48);
	margin-bottom: var(--space-48);
}

.navigation-overlay__menus > *:not(:last-child) {
	margin-bottom: var(--space-40);
}

@media (min-width: 768px) {
	.navigation-overlay__menus {
		margin-top: var(--space-64);
	}
}

@media (min-width: 1280px) {
	.navigation-overlay__menus {
		margin-top: 2.125rem; /* Align visually with top of header content. */
	}
}

/* Navigation list. */
.navigation-overlay__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.navigation-overlay__list__link {
	color: currentColor;
	text-decoration: none;
	transition: color 96ms linear;
}

.navigation-overlay__list__link[data-forced-focus] {
	outline: 0;
}

.navigation-overlay__list__link[aria-current="page"],
.navigation-overlay__list__link:focus:not([data-forced-focus]),
.navigation-overlay__list__link:hover {
	color: var(--color-white);
}

.navigation-overlay__list__thumbnail {
	display: none;
}

@media (min-width: 1280px) and (hover: hover) {
	.navigation-overlay__list__thumbnail {
		position: absolute;
		top: 160px;
		left: var(--wrapper-spacing-from-wide);
		width: calc(((((100vw - (var(--wrapper-spacing-from-wide) * 2)) - (11 * var(--column-gap-from-wide))) / 12) * 4) + (3 * var(--column-gap-from-wide))); /* Absolute unit version for a 4/12 col. */
		height: auto;
	}

	.navigation-overlay__list__link:focus:not([data-forced-focus]) .navigation-overlay__list__thumbnail,
	.navigation-overlay__list__link:hover .navigation-overlay__list__thumbnail {
		display: block;
	}
}

@media (min-width: 1440px) and (hover: hover) {
	.navigation-overlay__list__thumbnail {
		left: calc((100% - var(--wrapper-max-width)) / 2);
		width: 400px;
	}
}

/* Slogan. */
.navigation-overlay__slogan {
	margin-right: 0;
	margin-left: auto;
	pointer-events: none;
	user-select: none;
	width: 138px;
	height: 64px;
}

@media (min-width: 1024px) {
	.navigation-overlay__slogan {
		width: 210px;
		height: 98px;
	}
}

@media (min-width: 1280px) {
	.navigation-overlay__slogan {
		width: 300px;
		height: 140px;
	}
}

.navigation-overlay__slogan__image {
	display: block;
	width: 100%;
	height: 100%;
}
