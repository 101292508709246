.section {
	margin-top: var(--section-spacing);
	margin-bottom: var(--section-spacing);
	position: relative;
	z-index: 1;
}

@media (min-width: 768px) {
	.section {
		margin-top: var(--section-spacing-from-small);
		margin-bottom: var(--section-spacing-from-small);
	}
}

@media (min-width: 1024px) {
	.section {
		margin-top: var(--section-spacing-from-medium);
		margin-bottom: var(--section-spacing-from-medium);
	}
}

@media (min-width: 1440px) {
	.section {
		margin-top: var(--section-spacing-from-large);
		margin-bottom: var(--section-spacing-from-large);
	}
}

.section:not(.section[class*="theme-"]) + .section {
	margin-top: 0;
}

.section.theme-site + .section:not(.section[class*="theme-"]) {
	margin-top: 0;
}

/* #region Themed sections. */
.section[class*="theme-"] {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: var(--section-spacing);
	padding-bottom: var(--section-spacing);
}

@media (min-width: 768px) {
	.section[class*="theme-"] {
		padding-top: var(--section-spacing-from-small);
		padding-bottom: var(--section-spacing-from-small);
	}
}

@media (min-width: 1024px) {
	.section[class*="theme-"] {
		padding-top: var(--section-spacing-from-medium);
		padding-bottom: var(--section-spacing-from-medium);
	}
}

@media (min-width: 1440px) {
	.section[class*="theme-"] {
		padding-top: var(--section-spacing-from-large);
		padding-bottom: var(--section-spacing-from-large);
	}
}

.section.theme-black + .section.theme-black,
.section.theme-green + .section.theme-green,
.section.theme-site + .section.theme-site,
.section.theme-white + .section.theme-white {
	padding-top: 0;
}
/* #endregion */

/* #region Fitted sections. */
/**
 * Sections with screen filling components (e.g. Image Banner) have regular
 * margins when used between other regular sections, but look better without
 * top or bottom margins when preceded / succeeded by a differently themed
 * component (e.g. themed section, site footer). We use this class to reset
 * the proper margins and try to make the section "fit" nicely.
 */
/* Reset bottom margin if last child in content. */
.l-site__content .section--fit:not(.section[class*="theme-"]):last-child {
	margin-bottom: 0;
}

/* Reset top margin if following themed section. */
.section[class*="theme-"] + .section--fit:not(.section[class*="theme-"]) {
	margin-top: 0;
}

/* Fit top of succeeding themed section. */
.section--fit:not(.section[class*="theme-"]) + .section[class*="theme-"] {
	margin-top: calc(var(--section-spacing) * -1);
}

@media (min-width: 768px) {
	.section--fit:not(.section[class*="theme-"]) + .section[class*="theme-"] {
		margin-top: calc(var(--section-spacing-from-small) * -1);
	}
}

@media (min-width: 1024px) {
	.section--fit:not(.section[class*="theme-"]) + .section[class*="theme-"] {
		margin-top: calc(var(--section-spacing-from-medium) * -1);
	}
}

@media (min-width: 1440px) {
	.section--fit:not(.section[class*="theme-"]) + .section[class*="theme-"] {
		margin-top: calc(var(--section-spacing-from-large) * -1);
	}
}
/* #endregion */

/* #region Top section. */
.section--top {
	z-index: 2;
}
/* #endregion */

/* #region First sections in site content. */
/**
 * From Wide and up, the first section inside the site content must account for
 * the sticky site header. The header height is subtracted from the top spacing.
 *
 * Between Wide and Large, the wrapper spacing isn't enough to fully clear the
 * site header, so we give it a little extra nudge of 24px.
 *
 * For themed section we make the section pull itself up behind the site header.
 * This way we get a seamless, themed top section.
 */

/* from-wide,desktop */
@media (min-width: 1280px) {
	.l-site__content *.section:first-of-type {
		margin-top: calc(var(--section-spacing-from-medium) - var(--site-header-height-from-wide-at-top) + var(--space-24));
	}

	.l-site__content *.section[class*="theme-"]:first-of-type {
		margin-top: calc(var(--site-header-height-from-wide-at-top) * -1);
		padding-top: calc(var(--section-spacing-from-medium) + var(--space-24));
	}
}

/* from-large */
@media (min-width: 1440px) {
	.l-site__content *.section:first-of-type {
		margin-top: calc(var(--section-spacing-from-large) - var(--site-header-height-from-wide-at-top));
	}

	.l-site__content *.section[class*="theme-"]:first-of-type {
		margin-top: calc(var(--site-header-height-from-wide-at-top) * -1);
		padding-top: var(--section-spacing-from-large);
	}
}
/* #endregion */

/* #region section Anchor Offsets */
.section {
	scroll-margin-top: var(--site-header-height);
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.section {
		scroll-margin-top: var(--site-header-height-from-wide);
	}
}

.l-site--with-translations .section,
.l-site--with-sub-navigation .section {
	scroll-margin-top: calc(var(--site-header-height) * 2);
}

.l-site--with-sub-navigation.l-site--with-translations .section {
	scroll-margin-top: calc(var(--site-header-height) * 3);
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.l-site--with-translations .section {
		scroll-margin-top: var(--site-header-height);
	}

	.l-site--with-sub-navigation.l-site--with-translations .section {
		scroll-margin-top: calc(var(--site-header-height) * 2);
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.l-site--with-translations .section {
		scroll-margin-top: var(--site-header-height-from-wide);
	}

	.l-site--with-sub-navigation .section,
	.l-site--with-sub-navigation.l-site--with-translations .section {
		scroll-margin-top: calc(var(--site-header-height-from-wide) * 2);
	}
}

/* #region section Anchor Offsets */
