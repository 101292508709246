.cookie-notice {
	background-color: var(--color-white);
	box-sizing: border-box;
	display: none;
	opacity: 0;
	pointer-events: none;
	position: fixed;
	right: var(--space-16);
	bottom: 5.5rem;
	left: var(--space-16);
	z-index: var(--z-index-modal-dialog);
}

.cookie-notice[data-state="opening"],
.cookie-notice[data-state="open"],
.cookie-notice[data-state="closing"] {
	display: block;
	opacity: 1;
	pointer-events: auto;
}

@media (min-width: 768px) {
	.cookie-notice {
		right: var(--wrapper-spacing-from-small);
		bottom: var(--space-24);
		left: auto;
		max-width: 25.25rem;
	}
}

@media (min-width: 1280px) {
	.cookie-notice {
		right: var(--wrapper-spacing-from-wide);
		bottom: var(--space-48);
	}
}

@media (min-width: 1440px) {
	.cookie-notice {
		right: calc((100% - var(--wrapper-max-width)) / 2);
		bottom: var(--space-56);
	}
}

.cookie-notice__container {
	font-size: 0.625rem;
	line-height: 1.4;
	padding: 0.5rem 0.75rem 0.75rem;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.cookie-notice__container {
		font-size: 0.75rem;
		line-height: 1.33;
		padding: var(--space-16);
	}
}

.cookie-notice__cta-list {
	margin-top: 0.5rem;
	margin-bottom: 0;
	padding: 0;
}

@media (min-width: 768px) {
	.cookie-notice__cta-list {
		margin-top: 0.8125rem;
	}
}

.cookie-notice__cta-list__item {
	display: inline-block;
}

.cookie-notice__button {
	appearance: none;
	background-color: var(--color-black);
	border: none;
	border-radius: 0.625rem;
	color: var(--color-white);
	cursor: pointer;
	font-size: 0.75rem;
	letter-spacing: 0.0625rem;
	line-height: 1;
	margin-right: 0.5rem;
	padding-top: 0.0625rem;
	padding-bottom: 0.0625rem;
	text-align: center;
	text-transform: uppercase;
	transition:
		background-color 96ms linear,
		color 96ms linear;
	min-width: 4.9375rem;
}

.cookie-notice__button:hover {
	background-color: var(--color-green);
	color: var(--color-black);
}

@media (min-width: 768px) {
	.cookie-notice__button {
		margin-right: var(--space-16);
		padding-top: 0.21875rem;
		padding-bottom: 0.21875rem;
		min-width: 5.6875rem;
	}
}
