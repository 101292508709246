/**
 * HTML structure:
 *
 * <div class="marquee-link"> <!-- A. -->
 *     <div class="marquee-container"> <!-- B. -->
 *         <div class="marquee"> <!-- C. -->
 *             …
 *         </div>
 *     </div>
 * </div>
 */

/* A. Link. */
.marquee-link {
	color: var(--color-foreground);
	display: block;
	text-decoration: none;
	transition: color 96ms linear;
}

.marquee-link:focus,
.marquee-link:hover {
	color: var(--color-highlight);
	outline: 0;
}

/* B. Container. */
.marquee-container {
	overflow: hidden;
	width: 100%;
}

/* C. Marquee text. */
.marquee {
	display: inline-block;
	font-size: 3.125rem;
	text-transform: uppercase;
	white-space: nowrap;
}

@media (min-width: 1440px) {
	.marquee {
		font-size: 6rem;
	}
}
