@font-face {
	font-family: "UntitledSans";
	src: url("../fonts/untitled-sans-web-regular.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "UntitledSans";
	src: url("../fonts/untitled-sans-web-medium.woff2") format("woff2");
	font-style: normal;
	font-weight: 500;
}
