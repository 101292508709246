/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.form {
	display: block;
	position: relative;
	width: 100%;
}

/* #region Resets */
.form fieldset,
.form input:not([type='submit']):not([type='reset']), /* Only if these have rounded button styles. */
.form label,
.form select,
.form textarea {
	border-radius: 0; /* Reset browser defaults (e.g. iOS Safari). */
}
/* #endregion */

/* #region Group. */
.form__group {
	border: 0;
	margin: 0;
	padding: 0;
	width: 100%;
}
/* #endregion */

/* #region Spacing. */
.form__group:not(:last-child),
.form__group__title:not(:last-child),
.form__row:not(:last-child) {
	margin-bottom: var(--space-48);
}
/* #endregion */

/* #region Row. */
.form__row--disabled {
	cursor: not-allowed;
	opacity: 0.3;
}

.form__row--disabled * {
	pointer-events: none;
}

@media (min-width: 768px) and (max-width: 1023px) {
	.form__row:not(:last-child) {
		margin-bottom: var(--space-64); /* Match column. */
	}
}
/* #endregion */

/* #region Field container. */
.form__field-container {
	position: relative;
}
/* #endregion */

/* #region Label clone. */
.form__label-clone {
	pointer-events: none;
	position: absolute;
	top: 0.75rem;
	left: 0;
	transition: transform 400ms cubic-bezier(0.695, 0.275, 0, 1);
	user-select: none;
	z-index: 2;
}

.form__input:not([empty]) + .form__label-clone,
.form__input:focus + .form__label-clone,
.form__textarea:not([empty]) + .form__label-clone,
.form__textarea:focus + .form__label-clone {
	transform: translateY(-1.875rem);
}
/* #endregion */

/* #region Input fields. */
.form__input,
.form__select,
.form__textarea {
	background: none;
	border: 0;
	border-bottom: 1px solid var(--color-foreground-alpha-55);
	box-sizing: border-box;
	color: currentColor;
	font-size: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
	line-height: inherit;
	margin-bottom: 1px;
	padding: 0.625rem 0;
	position: relative;
	width: 100%;
	z-index: 1;
}

.form__input:focus,
.form__select:focus,
.form__textarea:focus {
	border-bottom: 2px solid var(--color-highlight);
	margin-bottom: 0;
	outline: 0;
}
/* #endregion */

/* #region Select. */
.form__select {
	appearance: none;
	background-image: url("../images/arrow-down.svg");
	background-position: right 0.875rem;
	background-repeat: no-repeat;
	background-size: 1.25rem;
	padding-right: 1.25rem;
}

.form__select::-ms-expand {
	display: none;
}

.form__select-label-clone {
	margin-bottom: 0.375rem;
	position: absolute;
	top: 0.75rem;
	left: 0;
	transform: translateY(-1.875rem);
}

.form__select[empty] {
	padding-top: 0;
	padding-bottom: 1.25rem;
}

.form__select[empty] + .form__select-label-clone {
	display: none;
	transform: none;
}

.form__select option[visually-hidden] {
	display: none;
}

.form__select:not([empty]) option[visually-hidden] {
	display: block;
}
/* #endregion */

/* #region Disabled inputs. */
.form__input[disabled],
.form__select[disabled],
.form__textarea[disabled] {
	border-color: var(--color-black);
}
/* #endregion */

/* #region Message. */
.form__message {
	max-width: 270px;
}
/* #endregion */

/* #region Checkboxes and radio's. */
.form__checkbox:not(:last-child),
.form__radio:not(:last-child) {
	margin-bottom: 0.5rem;
}

/* Reset actual inputs. */
.form__checkbox__input,
.form__radio__input {
	appearance: none;
	border: none !important;
	clip: rect(0 0 0 0) !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	white-space: nowrap !important;
	width: 1px !important;
	height: 1px !important;
}

/* Label container. */
.form__checkbox__label-container > *,
.form__radio__label-container > * {
	padding-left: calc(1rem + var(--space-16)); /* Checkbox/radio width + spacing. */
}

@media (min-width: 1280px) {
	.form__checkbox__label-container > *,
	.form__radio__label-container > * {
		padding-left: calc(1rem + var(--space-24)); /* Checkbox/radio width + spacing. */
	}
}

/* Labels. */
.form__checkbox__label,
.form__radio__label {
	cursor: pointer;
	display: inline-block;
	position: relative;
}

.form__checkbox__input[disabled] + .form__checkbox__label-container .form__checkbox__label,
.form__radio__input[disabled] + .form__radio__label-container .form__radio__label {
	cursor: not-allowed;
	opacity: 0.3;
}

/* Pseudo checkboxes and radio's. */
.form__checkbox__label::before,
.form__radio__label::before {
	border: 1px solid;
	box-sizing: border-box;
	content: "";
	position: absolute;
	top: 0.25rem;
	left: 0;
	width: 1rem;
	height: 1rem;
}

@media (min-width: 1280px) {
	.form__checkbox__label::before,
	.form__radio__label::before {
		top: 0.375rem;
	}
}

.form__checkbox__label::before {
	border-radius: 0.0625rem;
}

.form__radio__label::before {
	border-radius: 1rem;
}

.form__checkbox__input:focus + .form__checkbox__label-container .form__checkbox__label::before,
.form__checkbox__input:checked + .form__checkbox__label-container .form__checkbox__label::before,
.form__radio__input:focus + .form__radio__label-container .form__radio__label::before,
.form__radio__input:checked + .form__radio__label-container .form__radio__label::before {
	border-color: var(--color-highlight);
}

.form__checkbox__input:focus-visible + .form__checkbox__label-container .form__checkbox__label::before {
	border-width: 2px;
}

.form__radio__input:focus-visible + .form__radio__label-container .form__radio__label::before {
	border-width: 2px;
}

/* "Check marks". */
.form__checkbox__label::after,
.form__radio__label::after {
	background-color: var(--color-highlight);
	content: "";
	display: none;
	position: absolute;
	top: 0.4375rem;
	left: 0.1875rem;
	width: 0.625rem;
	height: 0.625rem;
}

@media (min-width: 1280px) {
	.form__checkbox__label::after,
	.form__radio__label::after {
		top: 0.5625rem;
	}
}

.form__checkbox__label::after {
	border-radius: 0.0625rem;
}

.form__radio__label::after {
	border-radius: 0.625rem;
}

.form__checkbox__input:checked + .form__checkbox__label-container .form__checkbox__label::after,
.form__radio__input:checked + .form__radio__label-container .form__radio__label::after {
	display: block;
}
/* #endregion */

/* #region Error Message */
.form__error-message {
	color: var(--color-error-state);
}
/* #endregion Error Message */
