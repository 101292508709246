.project-teaser-grid {
	display: grid;
	grid-row-gap: var(--space-24);
	list-style: none;
	margin: 0;
	padding: 0;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.project-teaser-grid {
		grid-column-gap: var(--space-10);
		grid-template-columns: repeat(2, 1fr);
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.project-teaser-grid {
		grid-row-gap: var(--space-40);
		grid-template-columns: repeat(4, 1fr);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.project-teaser-grid {
		grid-row-gap: var(--space-80);
		grid-column-gap: var(--space-16);
		grid-template-columns: repeat(4, 1fr);
	}
}

/* #region Highlighted */
.project-teaser-grid--highlighted {
	margin-bottom: var(--space-24);
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.project-teaser-grid--highlighted {
		margin-bottom: var(--space-40);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.project-teaser-grid--highlighted {
		margin-bottom: var(--space-80);
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.project-teaser-grid__item--highlighted {
		grid-column: span 2;
	}
}
/* #endregion Highlighted */

/* #region Link */
.project-teaser-grid__link {
	color: var(--color-black);
	text-decoration: none;
}

/* #endregion Link */

/* #region Image */
.project-teaser-grid__thumbnail img {
	margin: 0;
	width: 100%;
}
/* #endregion Image */

/* #region Info */
.project-teaser-grid__info {
	display: flex;
	flex-direction: column;
	margin-top: var(--space-10);
	align-items: flex-start;
}

.project-teaser-grid__title,
.project-teaser-grid__excerpt,
.project-teaser-grid__terms {
	margin-bottom: 0;
}

.project-teaser-grid__excerpt,
.project-teaser-grid__terms {
	margin-top: 0.3125rem;
}

.project-teaser-grid__title {
	text-decoration: underline;
	text-transform: uppercase;
	transition: 96ms background-color ease-in;
}

.project-teaser-grid__link:hover .project-teaser-grid__title,
.project-teaser-grid__link:focus .project-teaser-grid__title {
	background-color: var(--color-green);
	text-decoration: none;
}

.project-teaser-grid__terms {
	color: var(--color-foreground-alpha-55);
}
/* #endregion Info */

/* #region Divider */
.project-teaser-grid__divider {
	margin-block: var(--space-20);
}

/* from-large */
@media (min-width: 1440px) {
	.project-teaser-grid__divider {
		margin-block: var(--space-64);
	}
}
/* #endregion Divider */
