* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Body. */
body {
	font-family: var(--font-sans);
	font-size: 1rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: var(--font-weight-regular);
	letter-spacing: normal;
	line-height: 1.5;
}

@media (min-width: 1280px) {
	body {
		font-size: 1.125rem;
		line-height: 1.56;
	}
}

/* Headings. */
h1,
h2,
h3,
h4,
h5,
h6,
.type-a,
.type-b,
.type-c,
.type-f,
.type-g {
	margin-top: var(--space-56);
	margin-bottom: var(--space-56);
}

@media (min-width: 1280px) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.type-a,
	.type-b,
	.type-c,
	.type-f,
	.type-g {
		margin-top: var(--space-80);
		margin-bottom: var(--space-80);
	}
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
.type-a:first-child,
.type-b:first-child,
.type-c:first-child,
.type-f:first-child,
.type-g:first-child {
	margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
.type-a:last-child,
.type-b:last-child,
.type-c:last-child,
.type-f:last-child,
.type-g:last-child {
	margin-bottom: 0;
}

/* Paragraph. */
p,
.type-d,
.type-e,
.type-h,
.type-i,
.type-j {
	margin-top: 0;
	margin-bottom: var(--space-24);
}

p:first-child,
.type-d:first-child,
.type-e:first-child,
.type-h:first-child,
.type-i:first-child,
.type-j:first-child {
	margin-top: 0;
}

p:last-child,
.type-d:last-child,
.type-e:last-child,
.type-h:last-child,
.type-i:last-child,
.type-j:last-child {
	margin-bottom: 0;
}

/* Type classes. */

/**
 * Names used in Sketch design files:
 * - Mobile/H1/Left/Grey
 * - Mobile/H1/Left/White
 * - Desktop/H1/Left/Black
 * - Desktop/H1/Left/Black
 * - Desktop/H1/Left/White
 */
h1:not([class*="type-"]),
h2:not([class*="type-"]),
.type-a {
	font-family: var(--font-sans);
	font-size: 2rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: var(--font-weight-regular);
	letter-spacing: normal;
	line-height: 1.19;
}

@media (min-width: 1280px) {
	h1:not([class*="type-"]),
	h2:not([class*="type-"]),
	.type-a {
		font-size: 3.75rem;
		line-height: 1.13;
	}
}

/**
 * Names used in Sketch design files:
 * - Mobile/H2/Left/Black
 * - Mobile/H2/Left/White
 * - Desktop/H2/Left/Black
 * - Desktop/H2/Left/White
 */
h3:not([class*="type-"]),
.type-b {
	font-family: var(--font-sans);
	font-size: 1.5rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: var(--font-weight-regular);
	letter-spacing: normal;
	line-height: 1.33;
}

@media (min-width: 1280px) {
	h3:not([class*="type-"]),
	.type-b {
		font-size: 2.375rem;
		line-height: 1.32;
	}
}

/**
 * Names used in Sketch design files:
 * - Desktop/ParagraphExtraLarge/Black
 *
 * Uses Mobile H3 / Type D for smaller screens!
 */
.type-c {
	font-family: var(--font-sans);
	font-size: 1.25rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: var(--font-weight-medium);
	letter-spacing: normal;
	line-height: 1.4;
}

@media (min-width: 1280px) {
	.type-c {
		font-size: 2.25rem;
		font-weight: var(--font-weight-regular);
		line-height: 1.39;
	}
}

/**
 * Names used in Sketch design files:
 * - Mobile/H3/Left/Black
 * - Desktop/H3/Left/Black
 */
h4:not([class*="type-"]),
.type-d {
	font-family: var(--font-sans);
	font-size: 1.25rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: var(--font-weight-medium);
	letter-spacing: normal;
	line-height: 1.4;
}

@media (min-width: 1280px) {
	h4:not([class*="type-"]),
	.type-d {
		font-size: 1.5rem;
		line-height: 1.5;
	}
}

/**
 * Names used in Sketch design files:
 * - Mobile/ParagraphLarge/Left/Black
 * - Mobile/ParagraphLarge/Left/White
 * - Desktop/ParagraphLarge/Left/Black
 * - Desktop/ParagraphLarge/Left/White
 */
.type-e {
	font-family: var(--font-sans);
	font-size: 1.125rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: var(--font-weight-regular);
	letter-spacing: normal;
	line-height: 1.56;
}

@media (min-width: 1280px) {
	.type-e {
		font-size: 1.375rem;
		line-height: 1.5;
	}
}

/**
 * Names used in Sketch design files:
 * - Mobile/H4/Left/Black
 * - Desktop/H4/Left/Black
 */
h5:not([class*="type-"]),
.type-f {
	font-family: var(--font-sans);
	font-size: 1.125rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: var(--font-weight-medium);
	letter-spacing: normal;
	line-height: 1.44;
}

@media (min-width: 1280px) {
	h5:not([class*="type-"]),
	.type-f {
		font-size: 1.25rem;
		line-height: 1.5;
	}
}

/**
 * Names used in Sketch design files:
 * - Mobile/H5/Left/Black
 * - Mobile/Paragraph/Left/Black
 * - Desktop/H5/Left/Black
 * - Desktop/Paragraph/Left/Black/Medium
 */
h6:not([class*="type-"]),
.type-g {
	font-family: var(--font-sans);
	font-size: 1rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: var(--font-weight-medium);
	letter-spacing: normal;
	line-height: 1.5;
}

@media (min-width: 1280px) {
	h6:not([class*="type-"]),
	.type-g {
		font-size: 1.125rem;
		line-height: 1.44;
	}
}

/**
 * Names used in Sketch design files:
 * - Mobile/Paragraph/Left/Grey
 * - Mobile/Paragraph/Left/White
 * - Mobile/Paragraph/Right/Black
 * - Desktop/Paragraph/Left/Black
 * - Desktop/Paragraph/Left/Grey
 * - Desktop/Paragraph/Left/White
 * - Desktop/Paragraph/Right/Black
 *
 * This is the body font style.
 */
blockquote:not([class]),
blockquote.wp-block-quote,
.type-h {
	font-family: var(--font-sans);
	font-size: 1rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: var(--font-weight-regular);
	letter-spacing: normal;
	line-height: 1.5;
}

@media (min-width: 1280px) {
	.type-h {
		font-size: 1.125rem;
		line-height: 1.56;
	}
}

/**
 * Names used in Sketch design files:
 * - Mobile/ParagraphSmall/Left/Black
 * - Mobile/ParagraphSmall/Right/Black
 * - Desktop/ParagraphSmall/Left/Black
 */
.type-i {
	font-family: var(--font-sans);
	font-size: 0.875rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: var(--font-weight-regular);
	letter-spacing: normal;
	line-height: 1.43;
}

@media (min-width: 1280px) {
	.type-i {
		font-size: 0.9375rem;
		line-height: 1.6;
	}
}

/**
 * Names used in Sketch design files:
 * - Mobile/H6/Left/Black
 * - Desktop/H6/Left/Black
 */
.type-j {
	font-family: var(--font-sans);
	font-size: 0.75rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.05375rem;
	line-height: 1.5;
	text-transform: uppercase;
}

@media (min-width: 1280px) {
	.type-j {
		font-size: 0.875rem;
		letter-spacing: 0.0625rem;
		line-height: 1.71;
	}
}

.type-k {
	color: var(--color-green);
}

.type-l {
	font-size: 0.625rem; /* 10px */
	line-height: 1.4;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.type-l {
		font-size: 0.75rem; /* 12px */
	}
}

/* Blockquote. */
blockquote {
	margin: 0;
}

blockquote:not([class]),
blockquote.wp-block-quote {
	border-left: 4px solid var(--color-highlight);
	box-sizing: border-box;
	margin: var(--space-56) 0;
	padding: 0 0 0 var(--space-24);
}

blockquote:not([class]) > *:not(:last-child),
blockquote.wp-block-quote > *:not(:last-child) {
	margin-bottom: var(--space-24);
}

@media (min-width: 1280px) {
	blockquote:not([class]),
	blockquote.wp-block-quote {
		padding-left: var(--space-40);
	}

	blockquote:not([class]) > *:not(:last-child),
	blockquote.wp-block-quote > *:not(:last-child) {
		margin-bottom: var(--space-40);
	}
}

/**
 * Semantic text styles.
 *
 * These shouldn't exist too often in this theme. Admins will not have the
 * option to show text bold or italic.
 */
b,
blockquote:not([class]) > p:not(:first-child),
blockquote.wp-block-quote > p:not(:first-child),
cite,
em,
i,
strong {
	font-style: normal;
	font-weight: var(--font-weight-medium);
}
