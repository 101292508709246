/* Resets for reduced motion. */
@media (prefers-reduced-motion: reduce) {
	*,
	*::after,
	*::before {
		animation-delay: -1ms !important;
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		background-attachment: initial !important;
		transition-delay: -1ms !important;
		transition-duration: 0.01ms !important;
	}

	html {
		scroll-behavior: auto !important;
	}
}

html {
	scroll-behavior: smooth;
}

/* Body. */
body {
	background-color: var(--color-background);
	box-sizing: border-box;
	color: var(--color-foreground);
	position: relative;
	min-height: 100vh;
}

body.is-showing-overlay {
	overflow: hidden;
}

/* Form inputs. */
textarea {
	resize: none;
}

input,
button,
textarea,
select {
	font: inherit;
}

/* HR. */
hr {
	background-color: var(--color-foreground-alpha-20);
	border: none;
	margin-top: var(--section-spacing);
	margin-bottom: var(--section-spacing);
	height: 1px;
}

@media (min-width: 768px) {
	hr {
		margin-top: var(--section-spacing-from-small);
		margin-bottom: var(--section-spacing-from-small);
	}
}

@media (min-width: 1024px) {
	hr {
		margin-top: var(--section-spacing-from-medium);
		margin-bottom: var(--section-spacing-from-medium);
	}
}

@media (min-width: 1280px) {
	hr {
		margin-top: var(--section-spacing-from-large);
		margin-bottom: var(--section-spacing-from-large);
	}
}

.section hr {
	margin-top: 0;
	margin-bottom: 0;
}

/* Media. */
img,
video {
	display: block;
	max-width: 100%;
	height: auto;
}

/* Table. */
table,
th,
td {
	border: 1px solid currentColor;
}

table {
	border-collapse: collapse;
	box-sizing: border-box;
	margin: 0;
	width: 100%;
	max-width: 100%;
}

th,
td {
	box-sizing: border-box;
	padding: var(--space-16);
	text-align: left;
}

th {
	font-weight: var(--font-weight-medium);
}

@media (min-width: 1280px) {
	th,
	td {
		padding: var(--space-24);
	}
}

/* List styles. */
ol:not([class]),
ul:not([class]) {
	box-sizing: border-box;
	margin: 0 0 var(--space-24) 0;
}

ol:not([class]) ol:not([class]),
ol:not([class]) ul:not([class]),
ul:not([class]) ol:not([class]),
ul:not([class]) ul:not([class]) {
	padding-left: 2rem;
}

ol:not([class]):last-child,
ul:not([class]):last-child {
	margin-bottom: 0;
}

li ol:not([class]),
li ul:not([class]) {
	margin-bottom: 0;
}

ul:not([class]) {
	list-style: none;
	padding-left: 0;
}

ol:not([class]) {
	padding-left: 1.25rem;
}

ul:not([class]) li:not([class]) {
	margin: 0;
	padding-left: 0.875rem;
	position: relative;
}

ul:not([class]) li:not([class])::before {
	content: "• ";
	position: absolute;
	left: 0;
}

/* Link styles. */
a:not([class]) {
	background-color: var(--color-highlight-alpha-0);
	border-bottom: 1px solid;
	color: currentColor;
	display: inline;
	text-decoration: none;
	transition:
		background-color 96ms linear,
		color 96ms linear;
}

a:not([class]):focus,
a:not([class]):hover {
	background-color: var(--color-highlight);
	color: var(--color-foreground);
}

a:not([class]):focus:not(:focus-visible) {
	outline: 0;
}

p a:not([class]) {
	line-height: inherit;
}
