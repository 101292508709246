.selected-projects {
	position: relative;
}

/* Header. */
.selected-projects__header {
	border-bottom: 1px solid var(--color-foreground-alpha-20);
	margin-right: auto;
	margin-left: auto;
	padding-top: var(--space-16);
	padding-bottom: var(--space-16);
	width: calc(100% - (var(--wrapper-spacing) * 2));
}

@media (min-width: 768px) {
	.selected-projects__header {
		width: calc(100% - (var(--wrapper-spacing-from-small) * 2));
	}
}

@media (min-width: 1280px) {
	.selected-projects__header {
		left: var(--wrapper-spacing-from-wide);
		width: calc(100% - (var(--wrapper-spacing-from-wide) * 2));
	}
}

@media (min-width: 1440px) {
	.selected-projects__header {
		left: calc((100% - var(--wrapper-max-width)) / 2);
		width: var(--wrapper-max-width);
	}
}

/* List. */
.selected-projects__list {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
	width: 100%;
	z-index: 1;
}
