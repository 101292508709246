.hero {
	margin-top: calc(var(--site-header-height) * -1) !important;
}

/* from-wide,desktop  */
@media (min-width: 1280px) {
	.hero {
		margin-top: calc(var(--site-header-height-from-wide-at-top) * -1) !important;
	}
}

/* Pages with hero  */
.l-site--with-translations .hero {
	margin-top: calc(var(--site-header-height) * -2) !important;
}

/* from-medium,tablet-l  */
@media (min-width: 1024px) {
	.l-site--with-translations .hero {
		margin-top: calc(var(--site-header-height) * -1) !important;
	}
}

/* from-wide,desktop  */
@media (min-width: 1280px) {
	.l-site--with-translations .hero {
		margin-top: calc(var(--site-header-height-from-wide-at-top) * -1) !important;
	}
}
