.sub-navigation-menu {
	background-color: var(--color-green);
	display: flex;
	align-items: center;
	position: relative;
	transition:
		background-color 96ms linear,
		color 96ms linear,
		opacity 96ms linear;
	height: var(--site-header-height);
}

@media (min-width: 1024px) {
	.sub-navigation-menu {
		position: absolute;
		top: var(--site-header-height);
		right: 0;
		left: 0;
	}
}

@media (min-width: 1280px) {
	.sub-navigation-menu {
		top: var(--site-header-height-from-wide);
		height: var(--site-header-height-from-wide);
	}
}

.sub-navigation-menu--is-on-hero {
	display: none;
}

.sub-navigation-menu__list {
	display: flex;
	list-style: none;
	padding-left: 0;
}

.sub-navigation-menu__list__item {
	padding-right: var(--space-20);
}

/* from-large */
@media (min-width: 1440px) {
	.sub-navigation-menu__list__item {
		padding-right: var(--space-30);
	}
}

.sub-navigation-menu__list__link {
	color: currentColor;
	text-decoration: none;
}

.sub-navigation-menu__list__link:hover {
	text-decoration: underline;
}

.sub-navigation-menu__list__link[aria-current="page"] {
	background-color: var(--color-green);
	color: var(--color-black);
}

.sub-navigation-menu__list__link[aria-current="page"]:hover {
	text-decoration: none;
}

.sub-navigation-menu__list__link--is-active {
	background-color: var(--color-white);
}
