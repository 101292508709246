.contact-list {
}

/* #region Group. */
.contact-list__group {
	position: relative;
}
/* #endregion */

/* #region Group header */
.contact-list__group-header {
	margin-right: auto;
	margin-left: auto;
	width: 100%;
}

.contact-list__group-header__inner {
	border-bottom: 1px solid var(--color-foreground-alpha-20);
	padding-bottom: var(--space-56);
}

@media (min-width: 1024px) {
	.contact-list__group-header {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}

	.contact-list__group-header__inner {
		border-top: 1px solid var(--color-foreground-alpha-20);
		border-bottom: 0;
		padding-top: var(--space-24);
		padding-bottom: var(--space-24);
	}
}
/* #endregion */

/* #region List. */
.contact-list__group-list {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
	width: 100%;
	z-index: 1;
}
/* #endregion */
