.list-columns {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.list-columns {
		column-count: 2;
		column-gap: var(--column-gap-from-small);
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.list-columns {
		column-count: 2;
		column-gap: var(--column-gap-from-medium);
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.list-columns {
		column-count: 2;
		column-gap: var(--column-gap-from-wide);
	}
}

/* List item. */
.list-columns li {
	hyphens: auto;
	/* stylelint-disable-next-line declaration-property-value-keyword-no-deprecated */
	word-break: break-word;
	word-wrap: break-word;
}
