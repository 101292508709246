.credits {
	display: flex;
}

/* Thumbnail. */
.credits__thumbnail {
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: var(--space-16);
	width: 38px;
	height: 38px;
}

@media (min-width: 1280px) {
	.credits__thumbnail {
		margin-right: var(--space-24);
		width: 48px;
		height: 48px;
	}
}

/* Content. */
.credits__content:not(:only-child) {
	transform: translateY(-0.375rem); /* Align visually with top of thumbnail */
}

@media (min-width: 1280px) {
	.credits__content:not(:only-child) {
		transform: translateY(-0.4375rem); /* Align visually with top of thumbnail */
	}
}
