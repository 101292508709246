.arrow-link {
	color: currentColor;
	display: inline-block;
	padding-right: var(--space-10);
	text-decoration: none;
}

.arrow-link--block {
	padding-right: 0; /* Used as a block, not inline with other text. */
}

button.arrow-link {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	cursor: pointer;
	font-size: inherit;
	font-style: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
	line-height: inherit;
	outline: 0;
	padding: 0;
}

/* Label. */
.arrow-link__label {
	text-decoration: underline;
	transition: background-color 128ms linear;
}

.arrow-link:focus .arrow-link__label,
.arrow-link:hover .arrow-link__label {
	background-color: var(--color-green);
	text-decoration: none;
}

/* Icon. */
.arrow-link__icon {
	display: inline-block;
	margin-left: var(--space-10);
	transition: transform 400ms cubic-bezier(0.85, 0.035, 0.085, 0.985);
}

.arrow-link__icon--mirrored {
	margin-right: var(--space-10);
	margin-left: 0;
	transform: rotate(-180deg);
}

@media (hover: hover) {
	.arrow-link:focus .arrow-link__icon,
	.arrow-link:hover .arrow-link__icon {
		transform: translateX(var(--space-10));
	}

	.arrow-link:focus .arrow-link__icon--mirrored,
	.arrow-link:hover .arrow-link__icon--mirrored {
		transform: translateX(calc(-1 * var(--space-10))) rotate(-180deg);
	}
}

.arrow-link__icon svg {
	width: 0.875rem;
	height: 0.875rem;
}
