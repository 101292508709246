.site-footer {
	padding-top: var(--space-48);
	padding-bottom: var(--space-48);
}

@media (min-width: 768px) {
	.site-footer {
		padding-top: var(--space-56);
		padding-bottom: var(--space-56);
	}
}

@media (min-width: 1280px) {
	.site-footer {
		padding-top: var(--space-80);
		padding-bottom: var(--space-80);
	}
}

@media (min-width: 1024px) {
	.site-footer {
		display: flex;
	}
}

/* Credits. */
.site-footer__credits {
	text-transform: uppercase;
}

/* Navigation list. */
.site-footer__navigation-list {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

.site-footer__navigation-list__item {
	display: block;
	width: 100%;
}

.site-footer__navigation-list__link {
	color: currentColor;
	display: inline-block;
	padding-top: 0.3125rem; /* Increase tap target size. */
	padding-bottom: 0.3125rem; /* Increase tap target size. */
}

@media (hover: hover) {
	.site-footer__navigation-list__link {
		padding-top: 0; /* Reset for mouse users. */
		padding-bottom: 0; /* Reset for mouse users. */
	}
}
