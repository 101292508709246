.no-results {
	padding: var(--space-16);
}

.no-results--cream {
	background-color: var(--color-cream);
	color: var(--color-black);
}

.no-results p:first-child,
.no-results h2:first-child {
	margin-bottom: var(--space-16);
}

.no-results__inner {
	width: 83%;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.no-results {
		padding: var(--space-24);
	}

	.no-results p:first-child,
	.no-results h2:first-child {
		margin-bottom: var(--space-24);
	}

	.no-results__inner {
		width: 62.5%;
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.no-results {
		padding: var(--space-36);
	}

	.no-results p:first-child,
	.no-results h2:first-child {
		margin-bottom: var(--space-36);
	}

	.no-results__inner {
		width: 50%;
	}
}
