.search-form {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	width: 100%;
}

@media (min-width: 768px) {
	.search-form--mobile {
		display: none;
	}
}

@media (max-width: 767px) {
	.search-form--desktop {
		display: none;
	}
}

/* Input + Submit */
.search-form__input,
.search-form__submit-button {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	box-sizing: border-box;
	display: block;
	font-family: var(--font-sans);
	font-size: 1.5rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: var(--font-weight-regular);
	letter-spacing: normal;
	line-height: 60px;
	padding: 0;
	position: relative;
	transition: border-color 96ms linear;
	height: 60px;
	z-index: 1;
}

@media (min-width: 1280px) {
	.search-form__input,
	.search-form__submit-button {
		font-size: 2.375rem;
		line-height: 90px;
		height: 90px;
	}
}

/* Input. */
.search-form__input {
	border-top: 1px solid var(--color-foreground-alpha-55);
	border-bottom: 1px solid var(--color-foreground-alpha-55);
	border-left: 1px solid var(--color-foreground-alpha-55);
	padding-left: var(--space-16);
}

@media (min-width: 768px) {
	.search-form__input {
		padding-left: var(--space-24);
	}
}

.search-form--mobile .search-form__input {
	flex-grow: 0;
	flex-shrink: 0;
	width: calc(100% - 60px);
}

.search-form--desktop .search-form__input {
	flex-grow: 1;
	flex-shrink: 1;
	width: auto;
}

.search-form__input:focus {
	border-color: var(--color-highlight);
	outline: 0;
}

.search-form__input:focus:not(:focus-visible) {
	border-color: var(--color-foreground);
}

.search-form__input:focus-visible {
	border-color: var(--color-highlight);
}

.search-form__input::-webkit-input-placeholder {
	color: var(--color-foreground-alpha-55);
}

.search-form__input::-moz-placeholder {
	color: var(--color-foreground-alpha-55);
}

.search-form__input:-ms-input-placeholder {
	color: var(--color-foreground-alpha-55);
}

.search-form__input:-moz-placeholder {
	color: var(--color-foreground-alpha-55);
}

/* Submit button. */
.search-form__submit-button {
	border-top: 1px solid var(--color-foreground-alpha-55);
	border-right: 1px solid var(--color-foreground-alpha-55);
	border-bottom: 1px solid var(--color-foreground-alpha-55);
	cursor: pointer;
	flex-grow: 0;
	flex-shrink: 0;
	outline: 0;
}

.search-form--mobile .search-form__submit-button {
	width: 60px;
}

.search-form__submit-button::before {
	background: var(--color-white);
	background: linear-gradient(90deg, rgb(255 255 255 / 0) 0%, rgb(255 255 255 / 1) 70%, rgb(255 255 255 / 1) 100%);
	content: "";
	pointer-events: none;
	position: absolute;
	top: 0;
	left: -29px;
	width: 30px;
	height: 100%;
	z-index: 3;
}

/* Input focus. */
.search-form__input:focus + .search-form__submit-button {
	border-color: var(--color-highlight);
	outline: 0;
}

.search-form__input:focus:not(:focus-visible) + .search-form__submit-button {
	border-color: var(--color-foreground);
}

.search-form__input:focus-visible + .search-form__submit-button {
	border-color: var(--color-highlight);
}

/* Submit label. */
.search-form__submit-button__label {
	background-color: var(--color-highlight-alpha-0);
	margin-right: var(--space-24);
	margin-left: var(--space-24);
	position: relative;
	transition: background-color 96ms linear;
	z-index: 4;
}

.search-form__submit-button:focus .search-form__submit-button__label,
.search-form__submit-button:hover .search-form__submit-button__label {
	background-color: var(--color-highlight);
	border-color: var(--color-highlight);
}

/* Submit arrow (mobile only). */
.search-form__submit-button__arrow {
	display: inline-block;
	position: relative;
	transform: translateY(6px); /* Center vertically inside button. */
	width: 28px;
	height: 28px;
	z-index: 4;
}

.search-form__submit-button__arrow .icon {
	display: block;
	width: 100%;
	height: 100%;
}
