.language-switcher {
	opacity: 1;
	position: relative;
	transition: opacity 96ms linear;
	width: 100%;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.language-switcher {
		width: auto;
		width: max-content;
	}
}

/* #region Button */
.language-switcher__button {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	color: currentColor;
	cursor: pointer;
	display: inline-flex;
	font-weight: inherit;
	line-height: inherit;
	outline: 0;
	padding: 0;
	align-items: center;
	position: relative;
}

/* Language name. */
.language-switcher__button__language-name {
	display: inline-block;
	font-weight: var(--font-weight-medium);
	margin-right: 0.875rem;
	text-decoration: underline;
}

@media (hover: hover) {
	.language-switcher__button__language-name {
		background-color: var(--color-highlight-alpha-0);
		transition:
			background-color 96ms linear,
			color 96ms linear;
	}

	.language-switcher__button:focus .language-switcher__button__language-name,
	.language-switcher__button:hover .language-switcher__button__language-name {
		background-color: var(--color-green);
		color: var(--color-black);
	}
}

/* Icon. */
.language-switcher__button__icon {
	display: inline-block;
	transform: translateY(0.125rem); /* Align visually with label. */
}

.language-switcher__button .language-switcher__button__icon .icon {
	display: block;
	transform-origin: center center;
	transition: transform 280ms cubic-bezier(0.65, 0, 0.25, 1);
	width: 0.75rem;
	height: 0.75rem;
}

.language-switcher__button[aria-expanded="true"] .language-switcher__button__icon .icon {
	transform: rotate(180deg);
}

/* Mobile styles. */

/* up-to-medium */
@media (max-width: 1023px) {
	.language-switcher__button {
		background-color: var(--color-green);
		box-sizing: border-box;
		color: var(--color-black);
		width: 100%;
		height: var(--site-header-height);
	}

	.language-switcher__button__icon {
		flex-basis: auto;
		flex-grow: 1;
	}

	.language-switcher__button__icon .icon {
		margin-left: auto;
	}

	.l-site--with-sub-navigation.l-site--with-translations .language-switcher__button {
		background-color: var(--color-black);
		color: var(--color-white);
		margin-bottom: -1px; /* Prevent gap between border and background. */
	}
}

/* up-to-small */
@media (max-width: 767px) {
	.language-switcher__button {
		padding-right: var(--wrapper-spacing);
		padding-left: var(--wrapper-spacing);
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.language-switcher__button {
		padding-right: var(--wrapper-spacing-from-small);
		padding-left: var(--wrapper-spacing-from-small);
	}
}
/* #endregion */

/* #region List */
.language-switcher__list {
	background-color: var(--color-green);
	box-sizing: border-box;
	display: none;
	list-style: none;
	margin: 0;
	padding: 0 var(--wrapper-spacing) var(--space-16);
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
}

.l-site--with-sub-navigation.l-site--with-translations .language-switcher__list {
	background-color: var(--color-black);
	color: var(--color-white);
	position: relative;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.l-site--with-sub-navigation.l-site--with-translations .language-switcher__list {
		position: absolute;
		z-index: 1;
	}
}

.l-site--with-sub-navigation.l-site--with-translations .language-switcher__list__item .language-switcher__list__link {
	color: var(--color-white);
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.language-switcher__list {
		padding: 0.25rem 0.5625rem;
		top: calc(100% + var(--space-16));
		width: calc(100% - 1.0625rem);
	}
}

.language-switcher__list.mr-menu--expanded {
	display: block;
}
/* #endregion */

/* #region List item. */
.language-switcher__list__item {
	display: block;
	text-align: left;
	width: 100%;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.language-switcher__list__item {
		text-align: right;
	}
}
/* #endregion */

/* #region List link. */
.language-switcher__list__link {
	color: var(--color-black);
	font-weight: var(--font-weight-medium);
}

.language-switcher__list__item--active .language-switcher__list__link {
	font-weight: var(--font-weight-regular);
	pointer-events: none;
	text-decoration: none;
	user-select: none;
}
/* #endregion */
