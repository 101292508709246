:root {
	/* Color names. */
	--color-black: #000000;
	--color-blue: #0d8ce3;
	--color-cream: #f8f4ec;
	--color-green: #66f266;
	--color-white: #ffffff;
	--color-error-state: #e20000;

	/* Transparent color names. */
	--color-black-alpha-0: rgb(0 0 0 / 0);
	--color-black-alpha-30: rgb(0 0 0 / 0.3);
	--color-green-alpha-0: rgb(102 242 102 / 0);
	--color-white-alpha-0: rgb(255 255 255 / 0);
	--color-white-alpha-30: rgb(255 255 255 / 0.3);

	/* Site colors. */
	--color-background: #f8f4ec; /* is --color-cream. */
	--color-foreground: #000000; /* is --color-black. */
	--color-highlight: #66f266; /* is --color-green. */

	/* Transparent site color. */
	--color-foreground-alpha-20: rgb(0 0 0 / 0.2); /* ⚠️ Very low contrast: 1.6 on white background. */
	--color-foreground-alpha-55: rgb(0 0 0 / 0.55); /* Lowest transparency foreground can have on background without failing lvl AA contrast ratio. */
	--color-foreground-alpha-70: rgb(0 0 0 / 0.7);
	--color-highlight-alpha-0: rgb(102 242 102 / 0);
	/*  */
	--font-sans: "UntitledSans", "Helvetica Neue", sans-serif;
	--font-weight-regular: 400;
	--font-weight-medium: 500;

	/* Spaces. */
	--space-8: 0.5rem;
	--space-10: 0.625rem;
	--space-16: 1rem;
	--space-20: 1.25rem;
	--space-24: 1.5rem;
	--space-30: 1.875rem;
	--space-36: 2.25rem;
	--space-40: 2.5rem;
	--space-48: 3rem;
	--space-56: 3.5rem;
	--space-64: 4rem;
	--space-72: 4.5rem;
	--space-80: 5rem;
	--space-98: 6.125rem;
	--space-120: 7.5rem;
	--space-136: 8.5rem;

	/* Section spacing. */
	--section-spacing: 3rem; /* is --space-48. */
	--section-spacing-from-small: 4rem; /* is --space-64. */
	--section-spacing-from-medium: 5rem; /* is --space-80. */
	--section-spacing-from-large: 8.5rem; /* is --space-136. */

	/* Wrapper spacing. */
	--wrapper-spacing: 1rem; /* is --space-16. */
	--wrapper-spacing-from-small: 1.5rem; /* is --space-24. */
	--wrapper-spacing-from-wide: 6.125rem; /* is --space-98. */
	--wrapper-max-width: 1244px;

	/* Columns. */
	--column-gap: 0.75rem;
	--column-gap-from-small: 1.25rem; /* is --space-20. */
	--column-gap-from-medium: 0.625rem; /* is --space-10. */
	--column-gap-from-wide: 1rem; /* is --space-16. */
	/*  */
	--column-width: calc((100% - (5 * 0.75rem)) / 6);
	--column-width-from-small: calc((100% - (5 * 1.25rem)) / 6);
	--column-width-from-medium: calc((100% - (11 * 0.625rem)) / 12);
	--column-width-from-wide: calc((100% - (11 * 1rem)) / 12);
	--column-width-from-large: 89px;

	/* Site header. */
	--site-header-height: 50px;
	--site-header-height-from-wide: 64px;
	--site-header-height-from-wide-at-top: 88px; /* --site-header-height-from-wide + --space-24 */

	/* Sticky logo. */
	--sticky-logo-size: 60px;
	--sticky-logo-size-from-small: 80px;
	--sticky-logo-size-from-wide: 104px;

	/* Z-indices. */
	--z-index-modal-dialog: 104;
	--z-index-dialog: 103;
	--z-index-site-header: 102;
	--z-index-sticky-logo: 101;
	--z-index-site-content: 100;
}
