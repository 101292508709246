.filter-groups {
}

/* Group. */
.filter-groups__all-filters .filter-groups__group:not(:last-child) {
	margin-bottom: var(--space-16);
}

@media (min-width: 1024px) {
	.filter-groups__all-filters .filter-groups__group:not(:last-child) {
		margin-bottom: var(--space-24);
	}
}

/* List. */
.filter-groups__list {
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(-1 * var(--space-16));
	margin-bottom: 0;
	padding: 0;
}

@media (max-width: 1023px) {
	/**
	 * Hack-y reset on a column, ok for now. This will change post-launch when
	 * this component is refactored for a custom mobile overflow scroll.
	 */
	.filter-groups__list__title {
		margin-bottom: var(--space-16) !important;
	}
}

@media (min-width: 1280px) {
	.filter-groups__list {
		margin-top: calc(-1 * var(--space-24));
	}
}

.filter-groups__list__item {
	display: block;
	margin-top: var(--space-16);
}

.filter-groups__list__item:not(:last-child) {
	margin-right: var(--space-16);
}

@media (min-width: 1280px) {
	.filter-groups__list__item {
		margin-top: var(--space-24);
	}

	.filter-groups__list__item:not(:last-child) {
		margin-right: var(--space-24);
	}
}
