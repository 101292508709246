.timeline {
	display: grid;
	grid-row-gap: var(--space-10);
	grid-column-gap: 0.75rem;
	grid-template-columns: repeat(2, 1fr);
	list-style: none;
	margin: 0;
	padding: 0;
}

@media (min-width: 375px) {
	.timeline {
		grid-template-columns: repeat(3, 1fr);
	}
}

/* from-xsmall */
@media (min-width: 568px) {
	.timeline {
		grid-template-columns: repeat(4, 1fr);
	}
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.timeline {
		grid-template-columns: repeat(5, 1fr);
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.timeline {
		grid-row-gap: var(--space-10);
		grid-column-gap: var(--space-10);
		grid-template-columns: repeat(6, 1fr);
	}
}

.timeline__item-year {
	aspect-ratio: 3 / 2;
	background-color: var(--color-green);
	box-sizing: border-box;
	display: flex;
	padding: var(--space-10);
	justify-content: center;
	align-items: center;
}

.timeline__link {
	text-decoration: none;
}

/* #region Thumbnail */
.timeline__thumbnail img {
	filter: grayscale(100%) contrast(1.2);
	margin: 0;
	transition: 96ms filter ease-in;
	width: 100%;
}

.timeline__item:hover .timeline__thumbnail img {
	filter: none;
}
/* #endregion Thumbnail */

.timeline__info {
	margin-top: 0.3125rem;
}

/* #region Title */
.timeline__link .timeline__title {
	text-decoration: underline;
}

.timeline__title {
	color: var(--color-black);
	display: inline;
	margin-bottom: 0;
	text-transform: uppercase;
	transition: 96ms background-color ease-in;
}

.timeline__link:hover .timeline__title,
.timeline__link:focus .timeline__title {
	background-color: var(--color-green);
	text-decoration: none;
}
/* #endregion Title */

.timeline__location {
	color: var(--color-black);
	text-decoration: none;
}
