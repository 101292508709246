.summary {
}

/* Intro. */
.summary__intro:not(:only-child) {
	margin-bottom: var(--section-spacing);
}

@media (min-width: 768px) {
	.summary__intro:not(:only-child) {
		margin-bottom: var(--section-spacing-from-small);
	}
}

@media (min-width: 1024px) {
	.summary__intro {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.summary__intro:not(:only-child) {
		margin-bottom: var(--section-spacing-from-medium);
	}
}

@media (min-width: 1440px) {
	.summary__intro:not(:only-child) {
		margin-bottom: var(--section-spacing-from-large);
	}
}

/* Intro title. */
@media (max-width: 1023px) {
	.summary__intro__title-container:not(:last-child) {
		margin-bottom: var(--space-48);
	}
}

@media (min-width: 1024px) {
	.summary__intro__title-container {
		flex-basis: calc((var(--column-width-from-medium) * 5) + (var(--column-gap-from-medium) * 4));
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: calc(var(--column-width-from-medium) + (var(--column-gap-from-medium) * 2));
	}
}

@media (min-width: 1280px) {
	.summary__intro__title-container {
		flex-basis: calc((var(--column-width-from-wide) * 5) + (var(--column-gap-from-wide) * 4));
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: calc(var(--column-width-from-wide) + (var(--column-gap-from-wide) * 2));
	}
}

/* Intro content. */
@media (min-width: 1024px) {
	.summary__intro__content {
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 1;
	}
}

/* Points list. */
.summary__points {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

@media (min-width: 768px) {
	.summary__points {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: flex-start;
	}
}

/* Single point. */
@media (max-width: 767px) {
	.summary__point:not(:last-child) {
		margin-bottom: var(--space-48);
	}
}

@media (min-width: 768px) {
	.summary__point {
		flex-grow: 0;
		flex-shrink: 0;
		margin-top: var(--space-48);
		margin-right: var(--space-10);
		width: calc((100% - (3 * var(--space-10))) / 4);
	}

	.summary__point:nth-child(4n + 4) {
		margin-right: 0;
	}

	.summary__point:first-child,
	.summary__point:nth-child(2),
	.summary__point:nth-child(3),
	.summary__point:nth-child(4) {
		margin-top: 0;
	}
}

@media (min-width: 1280px) {
	.summary__point {
		width: calc((100% - (3 * var(--space-16))) / 4);
	}

	.summary__point:nth-child(4n + 1),
	.summary__point:nth-child(4n + 2),
	.summary__point:nth-child(4n + 3) {
		margin-right: var(--space-16);
	}
}

/* Point title. */
.summary__point__title {
	hyphens: auto;
	margin-bottom: 0.5rem;
	word-break: break-all;
}

/* Point content. */
@media (min-width: 768px) {
	.summary__point__content-container {
		max-width: 160px;
	}
}

@media (min-width: 1024px) {
	.summary__point__content-container {
		max-width: 215px;
	}
}

@media (min-width: 1440px) {
	.summary__point__content-container {
		max-width: 262px;
	}
}
