/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.landing-hero {
	margin-top: calc(var(--site-header-height) * -1) !important;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.landing-hero {
		margin-top: calc(var(--site-header-height-from-wide-at-top) * -1) !important;
	}
}

/* up-to-medium */
@media (max-width: 1023px) {
	body[is-translatable] .landing-hero {
		margin-top: calc(var(--site-header-height) * -2) !important;
	}
}

/* Video container (Video + Title). */
.landing-hero__video-container {
	color: var(--color-black);
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100vh;
}

.landing-hero__video-container--is-dark {
	background-color: var(--color-black);
	color: var(--color-white);
}

@supports (object-fit: cover) {
	.landing-hero__video-container {
		background-color: transparent;
	}
}

/* Play trigger. */
.landing-hero__play-trigger-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
}

.landing-hero__play-trigger-container[hidden] {
	display: none;
}

.landing-hero__play-trigger {
	/* Button reset */
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	color: currentColor;
	cursor: pointer;
	display: block;
	font-size: inherit;
	font-style: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
	line-height: inherit;
	outline: 0;
	padding: 0;
	transform: translateY(1.5rem); /* Offset height of text label. */
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.landing-hero__play-trigger {
		transform: translateY(1.75rem); /* Offset height of text label. */
	}
}

.landing-hero__play-trigger[hidden] {
	opacity: 0;
	pointer-events: none;
	user-select: none;
}

.landing-hero__play-trigger__background,
.landing-hero__play-trigger__label {
	pointer-events: none;
}

.landing-hero__play-trigger__background {
	/* Background image fallback for IE */
	background-image: url("../images/for-the-love-of-life.svg");
	background-repeat: no-repeat;
	background-size: contain;
	opacity: 1;
	transition: opacity 128ms linear;
	width: 250px;
	height: 117.1875px;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.landing-hero__play-trigger__background {
		width: 480px;
		height: 225px;
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.landing-hero__play-trigger__background {
		width: 620px;
		height: 290.625px;
	}
}

@supports (mask-image: url("../images/for-the-love-of-life.svg")) {
	.landing-hero__play-trigger__background {
		/* Mask */
		background-color: currentColor;
		background-image: none;
		background-size: contain;
		mask-image: url("../images/for-the-love-of-life.svg");
		transition:
			background-color 128ms linear,
			opacity 128ms linear;
	}

	.landing-hero__play-trigger:hover .landing-hero__play-trigger__background,
	.landing-hero__play-trigger:focus .landing-hero__play-trigger__background {
		background-color: var(--color-green);
	}
}

.landing-hero__play-trigger__label {
	color: currentColor;
	display: inline-block;
	font-weight: var(--font-weight-medium);
	margin-top: var(--space-16);
	text-decoration: underline;
	transition: color 128ms linear;
}

.landing-hero__play-trigger:hover .landing-hero__play-trigger__label,
.landing-hero__play-trigger:focus .landing-hero__play-trigger__label {
	color: var(--color-green);
}

/* Video. */
.landing-hero__video {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 100%;
	height: auto;
	z-index: 1;
}

@supports (object-fit: cover) {
	.landing-hero__video {
		object-fit: cover;
		position: relative;
		top: auto;
		left: auto;
		transform: none;
		height: 100%;
	}
}

/* Content. */
.landing-hero__content > p {
	/* Force wysiwyg content to follow parent type class. */
	font-family: inherit;
	font-size: inherit;
	font-style: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
	line-height: inherit;
}
