/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.teaser {
}

/* Inner. */
.teaser__inner {
	position: relative;
}

/* #region Teaser Link */
.teaser__link {
	color: currentColor;
	display: block;
	padding-top: var(--space-16);
	padding-bottom: var(--space-16);
	text-decoration: none;
	width: 100%;
}

@media (min-width: 768px) {
	.teaser__link:focus,
	.teaser__link:hover {
		text-decoration: underline;
	}
}

@media (min-width: 1280px) {
	.teaser__link {
		padding-top: var(--space-40);
		padding-bottom: var(--space-40);
	}
}

/* #region Teaser Link before/after */

/* up-to-small */
@media (max-width: 767px) {
	/* Pseudo top border. */
	.teaser__link::before {
		background-color: var(--color-foreground-alpha-20);
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 1px;
	}

	.teaser-list:not([has-hovered-teaser]) .teaser[is-active] .teaser__link::before,
	.teaser__link:focus::before,
	.teaser__link:hover::before {
		background-color: var(--color-foreground);
		width: 100%;
	}

	/* Last child in teaser list also has a bottom pseudo-border. */
	.teaser-list .teaser-list__item:last-child .teaser__link::after {
		background-color: var(--color-foreground-alpha-20);
		content: "";
		position: absolute;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 1px;
	}
}

/* #endregion Teaser Link before/after */
/* #endregion Teaser Link */

/* #region Teaser Grid */
.teaser__grid {
	display: grid;
	grid-template-areas:
		"header"
		"thumbnail"
		"description";
	grid-template-rows: min-content min-content min-content;
	grid-template-columns: 1fr;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.teaser__grid {
		display: grid;
		grid-column-gap: 3px;
		grid-template-areas:
			"thumbnail header"
			"thumbnail description";
		grid-template-rows: min-content min-content;
		grid-template-columns: calc(100% / 12 * 5) calc((100% / 12 * 7) - 3px);
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.teaser__grid {
		grid-template-columns: calc(100% / 12 * 4) calc((100% / 12 * 8) - 3px);
	}
}
/* #endregion Teaser Grid */

/* #region Teaser Header */
.teaser__header {
	display: flex;
	grid-area: header;
	justify-content: space-between;
	align-items: flex-start;
}

.teaser__header:not(:last-child) {
	margin-bottom: var(--space-16);
}

@media (min-width: 768px) {
	.teaser__header:not(:last-child) {
		margin-bottom: var(--space-36);
	}
}

@media (min-width: 1280px) {
	.teaser__header:not(:last-child) {
		margin-bottom: var(--space-72);
	}
}

.teaser--project .teaser__header {
	text-transform: uppercase;
}
/* #endregion Teaser Header */

/* Title + excerpt + terms. */
.teaser__title,
.teaser__excerpt,
.teaser__terms {
	margin-bottom: 0;
	max-width: 614px;
}

.teaser__title {
	flex-shrink: 2;
	padding-right: 1rem; /* To ensure there is space between the meta info and the title */
}

.teaser__meta {
	flex-shrink: 0;
	margin-bottom: 0;
}

/* Terms. */
.teaser__terms {
	color: var(--color-foreground-alpha-55);
}

/* Thumbnail. */
.teaser__thumbnail {
	display: block;
	grid-area: thumbnail;
}

.teaser__thumbnail {
	width: calc((var(--column-width) * 4) + (var(--column-gap) * 3));
}

.teaser__thumbnail:not(:last-child) {
	margin-bottom: var(--space-16);
}

@media (min-width: 768px) {
	.teaser__thumbnail {
		margin-bottom: 0;
		width: 280px;
		height: auto;
	}

	.teaser__thumbnail:not(:last-child) {
		margin-bottom: 0;
	}
}

@media (min-width: 1024px) {
	.teaser__thumbnail {
		width: 300px;
	}
}

.teaser__thumbnail img {
	width: 100%;
}

.teaser__description {
	display: flex;
	flex-direction: column;
	grid-area: description;
	justify-content: flex-end;
}
