.label {
	appearance: none;
	background: none;
	background-color: var(--color-white);
	border: 0;
	border-radius: 0.625rem;
	color: var(--color-black);
	display: inline-block;
	font-family: var(--font-sans);
	font-size: 0.625rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: var(--font-weight-regular);
	letter-spacing: 0.0625rem;
	line-height: normal;
	outline: 0;
	padding: 0.125rem 0.75rem;
	position: relative;
	text-decoration: none;
	text-transform: uppercase;
	transition: background-color 96ms linear;
}

@media (min-width: 1280px) {
	.label {
		font-size: 0.75rem;
	}
}

.theme-green .label {
	transition:
		background-color 96ms linear,
		color 96ms linear;
}

/* Focus + Hover style (background). */
.label:focus,
.label:hover {
	background-color: var(--color-green);
}

.theme-green .label:focus,
.theme-green .label:hover {
	background-color: var(--color-black);
	color: var(--color-green);
}

/* Focus style (outlines). */
.label:focus::before {
	background: transparent;
	border: 2px solid var(--color-green);
	border-radius: 1rem;
	content: "";
	pointer-events: none;
	position: absolute;
	top: -4px;
	left: -4px;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
}
