.featured-projects {
	position: relative;
}

@media (min-width: 1280px) and (hover: hover) {
	.featured-projects {
		min-height: calc(295px + 0.625rem); /* This will be a bit off (max 23px) for screens 1281px to 1365px, which is fine, and removes the need for a way too complex calculation. */
	}
}

@media (min-width: 1366px) and (hover: hover) {
	.featured-projects {
		min-height: calc(318px + 0.625rem); /* This will be a bit off (max 22px) for screens 1367px to 1439px, which is fine, and removes the need for a way too complex calculation. */
	}
}

@media (min-width: 1440px) and (hover: hover) {
	.featured-projects {
		min-height: calc(340px + 0.625rem); /* Image height + image offset top (0.625rem) */
	}
}

/* List. */
.featured-projects__list {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

@media (min-width: 1280px) {
	.featured-projects__list {
		width: calc((var(--column-width-from-wide) * 7) + (var(--column-gap-from-wide) * 6));
	}
}

/* Link. */
.featured-projects__link {
	color: currentColor;
	display: inline-block;
	text-decoration: none;
	transition: color 96ms linear;
	max-width: 100%;
}

.featured-projects__link:focus,
.featured-projects__link:hover {
	color: var(--color-highlight);
}

/* Link header. */
.featured-projects__link__header {
	display: inline-flex;
	justify-content: flex-start;
	align-items: flex-end;
	width: 100%;
}

/* Link title. */
.featured-projects__link__title {
	display: inline-block;
	margin-bottom: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

/* Link location. */
.featured-projects__link__location {
	display: inline-block;
	margin-left: var(--space-16);
	text-transform: uppercase;
	transform: translateY(-0.125rem); /* Visually align with title. */
}

@media (min-width: 1280px) {
	.featured-projects__link__location {
		transform: translateY(-0.25rem); /* Visually align with title. */
	}
}

/* Link thumbnails */
.featured-projects__link__thumbnail {
	display: none;
}

@media (min-width: 1280px) and (hover: hover) {
	.featured-projects__link__thumbnail {
		position: absolute;
		top: 0;
		right: 0;
		transform: translateY(0.625rem); /* Visually align with titles. */
		width: calc((var(--column-width-from-wide) * 5) + (var(--column-gap-from-wide) * 4));
	}

	.featured-projects__link:focus .featured-projects__link__thumbnail,
	.featured-projects__link:hover .featured-projects__link__thumbnail {
		display: block;
	}
}
