/**
 * TOC:
 * 1. Column
 * 2. Column Size
 * 3. Column Pull
 * 4. Column Push
 */

/**
 * 1. Column: a column to place inside a row.
 *
 * e.g.
 * <div class="
 *		column
 *		column--…
 *	">
 * 	…
 * </div>
 */
.column {
	width: 100%;
}

@media (max-width: 767px) {
	.column:not(:last-child) {
		margin-bottom: var(--space-48);
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.column:not(:last-child) {
		margin-bottom: var(--space-64);
	}
}

@media (min-width: 1024px) {
	.column {
		flex-grow: 0;
		flex-shrink: 0;
	}

	.column:not(:last-child):not([class*="column--push-"]) {
		margin-right: var(--column-gap-from-medium);
	}
}

@media (min-width: 1280px) {
	.column:not(:last-child):not([class*="column--push-"]) {
		margin-right: var(--column-gap-from-wide);
	}
}

/**
 * 2. Column Size.
 *
 * A column has a defined size.
 * Used with "…--size-n" where 'n' = number of columns to take up.
 */

/* Size 3. */
@media (min-width: 1024px) {
	.column--size-3 {
		width: calc((var(--column-width-from-medium) * 3) + var(--column-gap-from-medium) * 2);
	}
}

@media (min-width: 1280px) {
	.column--size-3 {
		width: calc((var(--column-width-from-wide) * 3) + var(--column-gap-from-wide) * 2);
	}
}

@media (min-width: 1440px) {
	.column--size-3 {
		width: calc((var(--column-width-from-large) * 3) + var(--column-gap-from-wide) * 2);
	}
}

/* Size 4. */
@media (min-width: 1024px) {
	.column--size-4 {
		width: calc((var(--column-width-from-medium) * 4) + var(--column-gap-from-medium) * 3);
	}
}

@media (min-width: 1280px) {
	.column--size-4 {
		width: calc((var(--column-width-from-wide) * 4) + var(--column-gap-from-wide) * 3);
	}
}

@media (min-width: 1440px) {
	.column--size-4 {
		width: calc((var(--column-width-from-large) * 4) + var(--column-gap-from-wide) * 3);
	}
}

/* Size 5. */
@media (min-width: 1024px) {
	.column--size-5 {
		width: calc((var(--column-width-from-medium) * 5) + var(--column-gap-from-medium) * 4);
	}
}

@media (min-width: 1280px) {
	.column--size-5 {
		width: calc((var(--column-width-from-wide) * 5) + var(--column-gap-from-wide) * 4);
	}
}

@media (min-width: 1440px) {
	.column--size-5 {
		width: calc((var(--column-width-from-large) * 5) + var(--column-gap-from-wide) * 4);
	}
}

/* Size 6. */
@media (min-width: 1024px) {
	.column--size-6 {
		width: calc((var(--column-width-from-medium) * 6) + var(--column-gap-from-medium) * 5);
	}
}

@media (min-width: 1280px) {
	.column--size-6 {
		width: calc((var(--column-width-from-wide) * 6) + var(--column-gap-from-wide) * 5);
	}
}

@media (min-width: 1440px) {
	.column--size-6 {
		width: calc((var(--column-width-from-large) * 6) + var(--column-gap-from-wide) * 5);
	}
}

/* Size 7. */
@media (min-width: 1024px) {
	.column--size-7 {
		width: calc((var(--column-width-from-medium) * 7) + var(--column-gap-from-medium) * 6);
	}
}

@media (min-width: 1280px) {
	.column--size-7 {
		width: calc((var(--column-width-from-wide) * 7) + var(--column-gap-from-wide) * 6);
	}
}

@media (min-width: 1440px) {
	.column--size-7 {
		width: calc((var(--column-width-from-large) * 7) + var(--column-gap-from-wide) * 6);
	}
}

/* Size 8. */
@media (min-width: 1024px) {
	.column--size-8 {
		width: calc((var(--column-width-from-medium) * 8) + var(--column-gap-from-medium) * 7);
	}
}

@media (min-width: 1280px) {
	.column--size-8 {
		width: calc((var(--column-width-from-wide) * 8) + var(--column-gap-from-wide) * 7);
	}
}

@media (min-width: 1440px) {
	.column--size-8 {
		width: calc((var(--column-width-from-large) * 8) + var(--column-gap-from-wide) * 7);
	}
}

/**
 * 3. Column Pull.
 *
 * Pulls a column further along the track to create space before.
 * Used with "…--pull-n" where 'n' = number of columns to pull.
 */

/* Pull 1. */
@media (min-width: 1024px) {
	.column--pull-1 {
		margin-left: calc(var(--column-width-from-medium) + var(--column-gap-from-medium));
	}
}

@media (min-width: 1280px) {
	.column--pull-1 {
		margin-left: calc(var(--column-width-from-wide) + var(--column-gap-from-wide));
	}
}

@media (min-width: 1440px) {
	.column--pull-1 {
		margin-left: calc(var(--column-width-from-large) + var(--column-gap-from-wide));
	}
}

/* Pull 4. */
@media (min-width: 1024px) {
	.column--pull-4 {
		margin-left: calc((var(--column-width-from-medium) * 4) + (var(--column-gap-from-medium) * 4));
	}
}

@media (min-width: 1280px) {
	.column--pull-4 {
		margin-left: calc((var(--column-width-from-wide) * 4) + (var(--column-gap-from-wide) * 4));
	}
}

@media (min-width: 1440px) {
	.column--pull-4 {
		margin-left: calc((var(--column-width-from-large) * 4) + (var(--column-gap-from-wide) * 4));
	}
}

/* Pull 5. */
@media (min-width: 1024px) {
	.column--pull-5 {
		margin-left: calc((var(--column-width-from-medium) * 5) + (var(--column-gap-from-medium) * 5));
	}
}

@media (min-width: 1280px) {
	.column--pull-5 {
		margin-left: calc((var(--column-width-from-wide) * 5) + (var(--column-gap-from-wide) * 5));
	}
}

@media (min-width: 1440px) {
	.column--pull-5 {
		margin-left: calc((var(--column-width-from-large) * 5) + (var(--column-gap-from-wide) * 5));
	}
}

/* Pull 6. */
@media (min-width: 1024px) {
	.column--pull-6 {
		margin-left: calc((var(--column-width-from-medium) * 6) + (var(--column-gap-from-medium) * 6));
	}
}

@media (min-width: 1280px) {
	.column--pull-6 {
		margin-left: calc((var(--column-width-from-wide) * 6) + (var(--column-gap-from-wide) * 6));
	}
}

@media (min-width: 1440px) {
	.column--pull-6 {
		margin-left: calc((var(--column-width-from-large) * 6) + (var(--column-gap-from-wide) * 6));
	}
}

/**
 * 4. Column Push.
 *
 * Push following columns further along the track to create space behind.
 * Used with "…--push-n" where 'n' = number of columns to push.
 */

/* Push 1. */
@media (min-width: 1024px) {
	.column--push-1 {
		margin-right: calc(var(--column-width-from-medium) + (var(--column-gap-from-medium) * 2));
	}
}

@media (min-width: 1280px) {
	.column--push-1 {
		margin-right: calc(var(--column-width-from-wide) + (var(--column-gap-from-wide) * 2));
	}
}

@media (min-width: 1440px) {
	.column--push-1 {
		margin-right: calc(var(--column-width-from-large) + (var(--column-gap-from-wide) * 2));
	}
}

/* Push 2. */
@media (min-width: 1024px) {
	.column--push-2 {
		margin-right: calc((var(--column-width-from-medium) * 2) + (var(--column-gap-from-medium) * 3));
	}
}

@media (min-width: 1280px) {
	.column--push-2 {
		margin-right: calc((var(--column-width-from-wide) * 2) + (var(--column-gap-from-wide) * 3));
	}
}

@media (min-width: 1440px) {
	.column--push-2 {
		margin-right: calc((var(--column-width-from-large) * 2) + (var(--column-gap-from-wide) * 3));
	}
}

/* Push 3. */
@media (min-width: 1024px) {
	.column--push-3 {
		margin-right: calc((var(--column-width-from-medium) * 3) + (var(--column-gap-from-medium) * 4));
	}
}

@media (min-width: 1280px) {
	.column--push-3 {
		margin-right: calc((var(--column-width-from-wide) * 3) + (var(--column-gap-from-wide) * 4));
	}
}

@media (min-width: 1440px) {
	.column--push-3 {
		margin-right: calc((var(--column-width-from-large) * 3) + (var(--column-gap-from-wide) * 4));
	}
}

/**
 * 5. Column (additional class - WP).
 *
 * When the column6-6 is below a Carousel, we need to shrink the margin top.
 * WATCH OUT! - this is dangerous. Do not use it somewhere else!
 */
.section.has-negative-margin-top {
	margin-top: calc(var(--space-36) * -1) !important;
}

/* from-medium,tablet-l
 */
@media (min-width: 1024px) {
	.section.has-negative-margin-top {
		margin-top: calc(var(--space-56) * -1) !important;
	}
}

/* from-large
 */
@media (min-width: 1440px) {
	.section.has-negative-margin-top {
		margin-top: calc(var(--space-98) * -1) !important;
	}
}
