.video-controls-container {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 2;
}

.video-controls {
	margin-bottom: var(--space-24);
	text-align: right;
	width: 100%;
}

.video-control {
	appearance: none;
	background: none;
	background-color: transparent;
	border: 0;
	border-radius: 30px;
	color: currentColor;
	cursor: pointer;
	display: inline-block;
	font-size: 0;
	outline: 0;
	padding: 8px;
	position: relative;
}

.video-control:focus {
	border: 2px solid currentColor;
	padding: 6px;
}

.video-control:focus:not(:focus-visible) {
	border: 0;
	padding: 8px;
}

.video-control:focus-visible {
	border: 2px solid currentColor;
	padding: 6px;
}

.video-control .icon {
	width: 24px;
	height: 24px;
}

mr-mute-toggle:not([data-muted]) .icon-sound-on {
	display: none;
}

mr-mute-toggle[data-muted] .icon-sound-off {
	display: none;
}

mr-mute-toggle[data-has-no-audio-tracks] {
	display: none;
}
