.thought {
	width: 100%;
}

/* #region Content */
.thought__content {
	background-color: var(--color-white);
	border-radius: 5px;
	box-shadow: 4px 4px 4px 0 rgb(0 0 0 / 0.07);
	box-sizing: border-box;
	color: var(--color-foreground-alpha-55);
	display: inline-block;
	padding: 13px 15px;
	position: relative;
	transition:
		box-shadow 128ms ease-in-out,
		transform 128ms ease-in-out;
	width: 100%;
	min-width: 28px; /* for the dots */
}

@media (min-width: 1280px) {
	.thought__content {
		padding: 17px 23px;
	}
}

a.thought__content {
	text-decoration: none;
}

a.thought__content:hover,
a.thought__content:focus {
	box-shadow: 4px 6px 7px 0 rgb(0 0 0 / 0.07);
	transform: translate3d(0, -2px, 0);
}
/* #endregion */

/* #region Dots */
.thought__dots {
	display: flex;
	justify-content: space-between;
	position: absolute;
	right: 0;
	bottom: -18px;
	width: 28px;
	height: 8px;
}

.thought__dots__dot {
	background-color: var(--color-white);
	border-radius: 5px;
	box-shadow: 3px 3px 4px 0 rgb(0 0 0 / 0.07);
	width: 8px;
	height: 8px;
}

a.thought__content:hover .thought__dots__dot,
a.thought__content:focus .thought__dots__dot {
	box-shadow: 3px 4px 6px 0 rgb(0 0 0 / 0.07);
}
/* #endregion */
