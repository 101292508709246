.video {
	display: block;
	margin: 0;
	padding: 0;
	position: relative;
	width: 100%;
}

/* #region Image container. */
.video__container {
	overflow: hidden;
	position: relative;
}
/* #endregion */

/* #region Video. */
.video__asset {
	display: block;
	position: relative;
	width: 100%;
	height: auto;
	z-index: 1;
}
/* #endregion */

/* #region Label link. */
.video__label-container {
	position: absolute;
	right: var(--space-16);
	bottom: var(--space-16);
	max-width: calc(100% - (var(--space-16) * 2));
	z-index: 2;
}

@media (min-width: 1024px) {
	.video__label-container {
		right: var(--space-24);
		bottom: var(--space-24);
		max-width: calc(100% - (var(--space-24) * 2));
	}
}

.video__asset[controls] + .video__label-container {
	bottom: calc(75px + var(--space-16)); /* Don't block native controls. */
}

@media (min-width: 1024px) {
	.video__asset[controls] + .video__label-container {
		bottom: calc(75px + var(--space-24)); /* Don't block native controls. */
	}
}
/* #endregion */

/* #region Caption. */
.video__caption {
	display: block;
	margin: 0.625rem 0 0;
	margin-top: 0.625rem;
	padding: 0;
	width: 100%;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.video__caption {
		margin-top: var(--space-16);
	}
}
/* #endregion */
