.privacy-control {
}

.privacy-control__section {
	margin-bottom: var(--section-spacing);
}

@media (min-width: 768px) {
	.privacy-control__section {
		margin-bottom: var(--section-spacing-from-small);
	}
}

@media (min-width: 1024px) {
	.privacy-control__section {
		margin-bottom: var(--section-spacing-from-medium);
	}
}

@media (min-width: 1440px) {
	.privacy-control__section {
		margin-bottom: var(--section-spacing-from-large);
	}
}

.privacy-control__columns {
	margin-bottom: var(--section-spacing);
}

@media (min-width: 768px) {
	.privacy-control__columns {
		margin-bottom: var(--section-spacing-from-small);
	}
}

@media (min-width: 1024px) {
	.privacy-control__columns {
		margin-bottom: var(--section-spacing-from-medium);
	}
}

@media (min-width: 1440px) {
	.privacy-control__columns {
		margin-bottom: var(--section-spacing-from-large);
	}
}

@media (min-width: 1024px) {
	.privacy-control__columns {
		display: flex;
		justify-content: space-between;
	}
}

.privacy-control__column {
	width: 100%;
}

/* up-to-medium */
@media (max-width: 1023px) {
	.privacy-control__column:not(:last-child) {
		margin-bottom: var(--space-16);
	}
}

/* Size 6. */
@media (min-width: 1024px) {
	.privacy-control__column {
		flex-grow: 0;
		flex-shrink: 0;
		width: calc((var(--column-width-from-medium) * 6) + var(--column-gap-from-medium) * 5);
	}
}

@media (min-width: 1280px) {
	.privacy-control__column {
		width: calc((var(--column-width-from-wide) * 6) + var(--column-gap-from-wide) * 5);
	}
}

@media (min-width: 1440px) {
	.privacy-control__column {
		width: calc((var(--column-width-from-large) * 6) + var(--column-gap-from-wide) * 5);
	}
}

.privacy-control__column h3 {
	margin-bottom: var(--space-24);
}

@media (min-width: 1280px) {
	.privacy-control__column h3 {
		margin-bottom: var(--space-36);
	}
}

@media (min-width: 1440px) {
	.privacy-control__column h3 {
		margin-bottom: var(--space-48);
	}
}

/* #region Titles */
.privacy-control__section h2 {
	/* as h3, type-b */
	font-size: 1.5rem;
	font-weight: var(--font-weight-regular);
	line-height: 1.33;
}

@media (min-width: 1280px) {
	.privacy-control__section h2 {
		font-size: 2.375rem;
		line-height: 1.32;
	}
}

.privacy-control__section h3 {
	/*  as h4, type-d */
	font-family: var(--font-sans);
	font-size: 1.25rem;
	font-weight: var(--font-weight-medium);
	line-height: 1.4;
}

@media (min-width: 1280px) {
	.privacy-control__section h3 {
		font-size: 1.5rem;
		line-height: 1.5;
	}
}
/* #endregion Titles */

/* #region Radio inputs */
.privacy-control .form__radio {
	position: relative;
}

.privacy-control .form__radio__label {
	padding-left: calc(1rem + var(--space-16)); /* Checkbox/radio width + spacing. */
}

@media (min-width: 1280px) {
	.privacy-control .form__radio__label {
		padding-left: calc(1rem + var(--space-24)); /* Checkbox/radio width + spacing. */
	}
}

.privacy-control .form__radio__input:focus + .form__radio__label::before,
.privacy-control .form__radio__input:checked + .form__radio__label::before {
	border-color: var(--color-highlight);
}

.privacy-control .form__radio__input:checked + .form__radio__label::after {
	display: block;
}
/* #endregion Radio inputs */
