.site-header {
	display: flex;
	opacity: 1;
	align-items: center;
	position: relative;
	transition:
		background-color 96ms linear,
		color 96ms linear,
		opacity 96ms linear;
	height: var(--site-header-height);
}

@media (min-width: 1280px) {
	.site-header {
		height: var(--site-header-height-from-wide);
	}
}

/* Inner. */
.site-header__inner {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.site-header__icons {
	display: flex;
	align-items: center;
}

.site-header__navigation-menu {
	display: none;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.site-header__navigation-menu {
		display: flex;
		flex-grow: 1;
	}
}

.site-header__navigation-menu__list {
	display: flex;
	list-style: none;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.site-header__navigation-menu__list {
		padding-left: var(--space-20);
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.site-header__navigation-menu__list {
		padding-left: var(--space-40);
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.site-header__navigation-menu__list__item {
		padding-right: var(--space-20);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.site-header__navigation-menu__list__item {
		padding-right: var(--space-30);
	}
}

.site-header__navigation-menu__list__link {
	color: currentColor;
	text-decoration: none;
}

.site-header__navigation-menu__list__link:hover {
	text-decoration: underline;
}

.site-header__navigation-menu__list__link[aria-current="page"] {
	background-color: var(--color-green);
	color: var(--color-black);
}

.site-header__navigation-menu__list__link[aria-current="page"]:hover {
	text-decoration: none;
}

/* Translate. */
@media (max-width: 1023px) {
	.site-header__translate-container {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
	}
}

@media (min-width: 1024px) {
	.site-header__translate-container {
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: var(--column-gap-from-medium);
		width: calc((var(--column-width-from-medium) * 4) + (var(--column-gap-from-medium) * 3));
	}
}

@media (min-width: 1280px) {
	.site-header__translate-container {
		margin-right: var(--column-gap-from-wide);
		width: calc((var(--column-width-from-wide) * 4) + (var(--column-gap-from-wide) * 3));
	}
}

.site-header__translate-container:empty {
	display: none;
}

/* Buttons. */
.site-header__buttons-container {
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	justify-content: flex-end;
	align-items: center;
	width: auto;
}

/* Search link. */
.site-header__search-link {
	color: currentColor;
	display: inline-block;
	transition: color 96ms linear;
	width: 20px;
	height: 20px;
}

.site-header__search-link:focus,
.site-header__search-link:hover {
	color: var(--color-highlight);
}

.site-header__search-link:focus:not(:focus-visible) {
	outline: 0;
}

.site-header__search-link:not(:last-child) {
	margin-right: var(--space-24);
}

.site-header__search-link .icon {
	display: block;
	width: 100%;
	height: 100%;
}

/* #region Icons */
/* Hamburger. */
.site-header__icons__hamburger {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	color: currentColor;
	cursor: pointer;
	display: inline-block;
	margin-right: var(--space-20);
	padding: 0;
	transform: translateY(3px); /* Visually center. */
	transition: color 96ms linear;
	width: 26px;
	height: 26px;
}

@media (min-width: 1280px) {
	.site-header__icons__hamburger {
		transform: translateY(4px); /* Visually center. */
	}
}

.site-header__icons__hamburger:focus,
.site-header__icons__hamburger:hover {
	color: var(--color-highlight);
}

.site-header__icons__hamburger[data-forced-focus] {
	outline: 0;
}

.site-header__icons__hamburger:focus:not(:focus-visible) {
	outline: 0;
}

.site-header__icons__hamburger .icon {
	display: block;
	width: 100%;
	height: 100%;
}

/* Home */
.site-header__icons__home {
	color: currentColor;
	cursor: pointer;
	transition: color 96ms linear;
	width: 19px;
	height: 19px;
}

.site-header__icons__home:focus,
.site-header__icons__home:hover {
	color: var(--color-highlight);
}

.site-header__icons__home[data-forced-focus] {
	outline: 0;
}

.site-header__icons__home:focus:not(:focus-visible) {
	outline: 0;
}

.site-header__icons__home .icon {
	display: block;
	width: 100%;
	height: 100%;
}
/* #endregion Icons */
