.l-error {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* #region Inner */
.l-error__scroll-container {
	box-sizing: border-box;
	margin-right: auto;
	margin-left: auto;
	overflow-y: auto;
	padding: var(--space-24);
	width: calc(100% - (2 * var(--wrapper-spacing)));
	max-height: 100%;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.l-error__scroll-container {
		width: 328px;
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.l-error__scroll-container {
		width: 614px;
	}
}
/* #endregion */

/* #region Inner */
.l-error__inner {
	height: auto;
	min-height: 100%;
}

.l-error__inner > *:not(:last-child) {
	margin-bottom: var(--space-40);
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.l-error__inner > *:not(:last-child) {
		margin-bottom: var(--space-56);
	}
}
/* #endregion */

/* #region Content */
.l-error__content {
	max-width: 404px;
}
/* #endregion */

/* #region Logo */
.l-error__logo {
	display: block;
	text-decoration: none;
	width: var(--sticky-logo-size);
	height: var(--sticky-logo-size);
}

@media (min-width: 768px) {
	.l-error__logo {
		width: var(--sticky-logo-size-from-small);
		height: var(--sticky-logo-size-from-small);
	}
}

@media (min-width: 1280px) {
	.l-error__logo {
		width: var(--sticky-logo-size-from-wide);
		height: var(--sticky-logo-size-from-wide);
	}
}

.l-error__logo .icon {
	display: block;
	width: 100%;
	height: 100%;
}

.l-error__logo .icon__background {
	fill: var(--color-highlight);
}

.l-error__logo .icon__letter {
	fill: var(--color-white);
}

.l-error__logo:focus .icon__background,
.l-error__logo:hover .icon__background {
	fill: var(--color-white);
}

.l-error__logo:focus .icon__letter,
.l-error__logo:hover .icon__letter {
	fill: var(--color-highlight);
}
/* #endregion */
