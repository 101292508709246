.people-teaser-grid {
	display: grid;
	grid-row-gap: var(--space-24);
	list-style: none;
	margin: 0;
	padding: 0;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.people-teaser-grid {
		grid-column-gap: var(--space-10);
		grid-template-columns: repeat(2, 1fr);
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.people-teaser-grid {
		grid-row-gap: var(--space-40);
		grid-template-columns: repeat(4, 1fr);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.people-teaser-grid {
		grid-row-gap: var(--space-80);
		grid-column-gap: var(--space-16);
		grid-template-columns: repeat(4, 1fr);
	}
}

/* #region Thumbnail */
.people-teaser-grid__thumbnail img {
	margin: 0;
	width: 100%;
}

.people-teaser-grid__item img {
	filter: grayscale(100%);
	transition: 96ms filter ease-in;
}

.people-teaser-grid__item:hover img {
	filter: none;
}

.people-teaser-grid__thumbnail-placeholder {
	aspect-ratio: 3 / 2;
	background-color: var(--color-green);
	width: 100%;
}
/* #endregion Thumbnail */

/* #region Info */
.people-teaser-grid__info {
	display: flex;
	flex-direction: column;
	margin-top: 0.3125rem;
}

.people-teaser-grid__info__title,
.people-teaser-grid__info__subtitle {
	margin-bottom: 0;
}
/* #endregion Info */

/* #region Read more */
.people-teaser-grid__read-more__trigger {
	margin-top: var(--space-20);
}

.people-teaser-grid__read-more {
	-mrh-resets: button; /* button reset */
}

.people-teaser-grid__read-more span {
	border-bottom: 1px solid var(--color-black);
	display: flex;
	margin-top: var(--space-20);
	align-items: center;
}

.people-teaser-grid__read-more:hover span {
	border-bottom: none;
}

.people-teaser-grid__read-more .icon-plus {
	display: block;
	margin-left: 0.3125rem;
	width: 0.625rem;
	height: 0.625rem;
}
/* #endregion Read more */
