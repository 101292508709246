.person-detail {
	display: flex;
	flex-direction: column;
	padding-inline: var(--space-10);
	justify-content: center;
	align-items: center;
	height: auto;
	min-height: 100%;
}

@media (min-width: 390px) {
	.person-detail {
		padding-inline: var(--space-40);
	}
}

.person-detail mr-input-sink {
	display: flex;
	margin-bottom: var(--space-10);
	justify-content: flex-end;
}

.person-detail-wrapper {
	max-width: 34.375rem;
}

/* from-large */
@media (min-width: 1440px) {
	.person-detail-wrapper {
		max-width: 38.375rem;
	}
}

.person-detail__close-button {
	-mrh-resets: button; /* button reset */
	color: var(--color-white);
	text-decoration: underline;
}

.person-detail__close-button:hover {
	text-decoration: none;
}

.person-detail__card {
	background-color: var(--color-white);
}

.person-detail__card__thumbnail {
	position: relative;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.person-detail__card__thumbnail picture {
		display: flex;
		justify-content: flex-end;
	}
}

.person-detail__card__thumbnail img {
	width: 100%;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.person-detail__card__thumbnail img,
	.person-detail__card__thumbnail-placeholder__container {
		width: auto;
		height: 10.9375rem;
	}
}

/* from-large */
@media (min-width: 1440px) {
	.person-detail__card__thumbnail img,
	.person-detail__card__thumbnail-placeholder__container {
		height: 12.8125rem;
	}
}

.person-detail__card__thumbnail-placeholder {
	aspect-ratio: 3 / 2;
	background-color: var(--color-green);
	width: 100%;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.person-detail__card__thumbnail-placeholder__container {
		display: flex;
		justify-content: flex-end;
		width: auto;
	}

	.person-detail__card__thumbnail-placeholder {
		aspect-ratio: 3 / 2;
		background-color: var(--color-green);
		width: auto;
	}
}

/* #region Intro */
.person-detail__card__intro {
	position: absolute;
	bottom: 10px;
	left: 10px;
	z-index: 10;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.person-detail__card__intro {
		position: absolute;
		bottom: 50%;
		left: 30px;
		transform: translateY(50%);
	}
}

.person-detail__card__intro__title,
.person-detail__card__intro__subtitle {
	margin-bottom: 0;
}
/* #endregion Intro */

/* #region Content */
.person-detail__card__content {
	overflow-y: scroll;
	padding: var(--space-10);
	max-height: 18.75rem;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.person-detail__card__content {
		padding: var(--space-30);
		max-height: 20rem;
	}
}

/* from-large */
@media (min-width: 1440px) {
	.person-detail__card__content {
		padding: var(--space-40);
		max-height: 24.5rem;
	}
}

.person-detail__card__links {
	margin-top: var(--space-20);
}

/* from-large */
@media (min-width: 1440px) {
	.person-detail__card__links {
		margin-top: var(--space-24);
	}
}

.person-detail__card__link {
	color: var(--color-black);
	display: flex;
	align-items: center;
}

.person-detail__card__link .icon-arrow-forward {
	margin-left: var(--space-16);
	width: 0.75rem;
	height: 0.75rem;
}

/* from-large */
@media (min-width: 1440px) {
	.person-detail__card__link .icon-arrow-forward {
		width: 0.8125rem;
		height: 0.8125rem;
	}
}
/* #endregion Content */

/* #region Gradient */
.person-detail__card__gradient {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 100%;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.person-detail__card__gradient {
		right: 7.625rem;
		bottom: 2.0625rem;
		transform: rotate(90deg);
		width: 11rem;
		height: 6.75rem;
	}
}

/* from-large */
@media (min-width: 1440px) {
	.person-detail__card__gradient {
		right: 10rem;
		bottom: 3.5625rem;
		width: 12.875rem;
		height: 5.625rem;
	}
}

.person-detail__card__gradient::before {
	background: linear-gradient(to bottom, rgb(255 255 255 / 0) 0%, rgb(255 255 255 / 0.013) 8.1%, rgb(255 255 255 / 0.049) 15.5%, rgb(255 255 255 / 0.104) 22.5%, rgb(255 255 255 / 0.175) 29%, rgb(255 255 255 / 0.259) 35.3%, rgb(255 255 255 / 0.352) 41.2%, rgb(255 255 255 / 0.45) 47.1%, rgb(255 255 255 / 0.55) 52.9%, rgb(255 255 255 / 0.648) 58.8%, rgb(255 255 255 / 0.741) 64.7%, rgb(255 255 255 / 0.825) 71%, rgb(255 255 255 / 0.896) 77.5%, rgb(255 255 255 / 0.951) 84.5%, rgb(255 255 255 / 0.987) 91.9%, rgb(255 255 255 / 1) 100%);
	content: "";
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 60%;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.person-detail__card__gradient::before {
		width: 11rem;
		height: 6.75rem;
	}
}

/* from-large */
@media (min-width: 1440px) {
	.person-detail__card__gradient::before {
		width: 12.875rem;
		height: 5.625rem;
	}
}
/* #endregion Gradient */
