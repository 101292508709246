.doormat {
}

/**
 * Column A.
 *
 * Has custom type style (type-d up to wide, type-c from large up).
 */
.doormat__column-a {
	font-family: var(--font-sans);
	font-size: 1.25rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: var(--font-weight-medium);
	letter-spacing: normal;
	line-height: 1.4;
}

.doormat__column-a * {
	font-family: inherit;
	font-size: inherit;
	font-stretch: inherit;
	font-style: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
	line-height: inherit;
}

/* from-large
 */
@media (min-width: 1440px) {
	.doormat__column-a {
		font-size: 2.25rem;
		font-weight: var(--font-weight-regular);
		line-height: 1.39;
	}
}
