.contact-card {
}

/* Inner. */
.contact-card__inner {
	position: relative;
}

/* Link. */
.contact-card__content {
	color: currentColor;
	padding-top: var(--space-24);
	padding-bottom: var(--space-36);
	position: static;
	text-decoration: none;
	width: 100%;
}

@media (min-width: 768px) {
	.contact-card__content {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
}

@media (min-width: 1024px) {
	.contact-card__content {
		margin-left: auto;
		padding-bottom: var(--space-24);
		width: calc((var(--column-width-from-medium) * 8) + (var(--column-gap-from-medium) * 7));
	}
}

@media (min-width: 1280px) {
	.contact-card__content {
		width: calc((var(--column-width-from-wide) * 8) + (var(--column-gap-from-wide) * 7));
	}
}

/* Pseudo top border. */
.contact-card__content::before {
	background-color: var(--color-foreground-alpha-20);
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 1px;
}

@media (min-width: 1024px) {
	.contact-card__content::before {
		width: calc((var(--column-width-from-medium) * 8) + (var(--column-gap-from-medium) * 7));
	}
}

@media (min-width: 1280px) {
	.contact-card__content::before {
		width: calc((var(--column-width-from-wide) * 8) + (var(--column-gap-from-wide) * 7));
	}
}

.contact-card__content:focus::before,
.contact-card__content:hover::before {
	background-color: var(--color-foreground);
	width: 100%;
}

/* First child in contact list has no initial top border. */
.contact-list .contact-list__group-list__item:first-child .contact-card__content:not(:focus):not(:hover)::before {
	content: none;
	display: none;
}

/* Title + Info. */
@media (min-width: 768px) {
	.contact-card__title,
	.contact-card__info {
		flex-grow: 0;
		flex-shrink: 0;
		width: calc(50% - (var(--column-gap-from-small) / 2));
	}
}

@media (min-width: 1024px) {
	.contact-card__title,
	.contact-card__info {
		width: calc(50% - (var(--column-gap-from-medium) / 2));
	}
}

@media (min-width: 1280px) {
	.contact-card__title,
	.contact-card__info {
		width: calc(50% - (var(--column-gap-from-wide) / 2));
	}
}

/* Title. */
@media (min-width: 768px) {
	.contact-card__title {
		margin-right: var(--column-gap-from-small);
	}
}

@media (min-width: 1024px) {
	.contact-card__title {
		margin-right: var(--column-gap-from-medium);
	}
}

@media (min-width: 1280px) {
	.contact-card__title {
		margin-right: var(--column-gap-from-wide);
	}
}

/* Country + Name + Contact info. */
.contact-card__country,
.contact-card__name,
.contact-card__contact-info {
	margin-bottom: 0;
}

/* Contact info. */
.contact-card__contact-info {
	color: var(--color-foreground-alpha-55);
}

a.contact-card__contact-info {
	text-decoration: none;
	transition: color 96ms linear;
}

a.contact-card__contact-info:focus,
a.contact-card__contact-info:hover {
	color: var(--color-foreground);
}

/* Thumbnail. */
.contact-card__thumbnail {
	display: none;
}

@media (min-width: 1280px) and (hover: hover) {
	.contact-card__thumbnail {
		position: absolute;
		top: var(--space-24);
		left: 0;
		width: 320px;
		height: auto;
	}

	.contact-card__content:focus .contact-card__thumbnail,
	.contact-card__content:hover .contact-card__thumbnail {
		display: block;
	}
}

@media (min-width: 1366px) and (hover: hover) {
	.contact-card__thumbnail {
		width: 340px;
	}
}

@media (min-width: 1440px) and (hover: hover) {
	.contact-card__thumbnail {
		width: 360px;
	}
}
