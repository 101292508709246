.carousel {
	display: block;
	position: relative;
	width: 100%;
}

/* #region Controls. */
.carousel__controls {
	background-color: var(--color-white);
	border-radius: 0.625rem;
	color: var(--color-black);
	display: flex;
	overflow: hidden;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	justify-content: flex-start;
	align-items: center;
	position: absolute;
	bottom: var(--space-16);
	left: var(--space-16);
	height: 1rem;
	z-index: 2;
}

@media (min-width: 1024px) {
	.carousel__controls {
		bottom: var(--space-24);
		left: var(--space-24);
	}
}

@media (min-width: 1280px) {
	.carousel__controls {
		height: 1.1875rem;
	}
}
/* #endregion Control. */

/* #region Controls icons. */
.carousel__next-button,
.carousel__play-pause-toggle,
.carousel__previous-button {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	color: currentColor;
	cursor: pointer;
	display: inline-block;
	outline: 0;
	padding: 0;
	position: relative;
	width: 0.75rem;
	height: 100%;
}

.carousel__next-button:focus,
.carousel__play-pause-toggle-button:focus,
.carousel__previous-button:focus {
	background-color: var(--color-green);
}

.carousel__next-button:focus:not(:focus-visible) {
	background: none;
}

.carousel__play-pause-toggle:focus:not(:focus-visible) {
	background: none;
}

.carousel__previous-button:focus:not(:focus-visible) {
	background: none;
}

.carousel__next-button:focus-visible {
	background-color: var(--color-green);
}

.carousel__play-pause-toggle:focus-visible {
	background-color: var(--color-green);
}

.carousel__previous-button:focus-visible {
	background-color: var(--color-green);
}

.carousel__next-button:not(:last-child),
.carousel__play-pause-toggle:not(:last-child),
.carousel__previous-button:not(:last-child) {
	margin-right: 0.5rem;
}

.carousel__next-button span,
.carousel__play-pause-toggle span,
.carousel__previous-button span {
	pointer-events: none;
}

.carousel__next-button .icon,
.carousel__play-pause-toggle .icon,
.carousel__previous-button .icon {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.carousel__next-button .icon,
.carousel__previous-button .icon {
	width: 80%;
	height: 80%;
}

.carousel__previous-button .icon {
	transform: translate(-50%, -50%) scale(-1, 1);
}

.carousel__play-pause-toggle .icon {
	width: 90%;
	height: 90%;
}

.carousel[data-carousel-playing] .carousel__play-pause-toggle .icon-play,
.carousel:not([data-carousel-playing]) .carousel__play-pause-toggle .icon-pause-minimal {
	display: none;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.carousel__next-button,
	.carousel__play-pause-toggle,
	.carousel__previous-button {
		/* height: 1rem; */
		width: 1rem;
	}

	.carousel__next-button:not(:last-child),
	.carousel__play-pause-toggle:not(:last-child),
	.carousel__previous-button:not(:last-child) {
		margin-right: 0.25rem;
	}
}
/* #endregion Play / Pause toggle. */

/* #region Items. */
.carousel__items {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0 0 calc(100% / 5 * 3);
	position: relative;
	width: 100%;
	z-index: 1;
}

.carousel__items[data-aspect-ratio="1:1"] {
	padding-bottom: calc(100% / 1 * 1);
}

.carousel__items[data-aspect-ratio="2:1"] {
	padding-bottom: calc(100% / 2 * 1);
}

.carousel__items[data-aspect-ratio="2:3"] {
	padding-bottom: calc(100% / 2 * 3);
}

.carousel__items[data-aspect-ratio="3:2"] {
	padding-bottom: calc(100% / 3 * 2);
}

.carousel__items[data-aspect-ratio="3:4"] {
	padding-bottom: calc(100% / 3 * 4);
}

.carousel__items[data-aspect-ratio="4:3"] {
	padding-bottom: calc(100% / 4 * 3);
}

.carousel__items[data-aspect-ratio="5:3"] {
	padding-bottom: calc(100% / 5 * 3); /* Again, explicitly, should default change. */
}

.carousel__items[data-aspect-ratio="16:9"] {
	padding-bottom: calc(100% / 16 * 9);
}

.carousel__items[data-aspect-ratio="21:9"] {
	padding-bottom: calc(100% / 21 * 9);
}
/* #endregion */

/* #region Item. */
.carousel__item {
	display: none;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	transition: opacity 128ms linear;
	width: 100%;
	height: 100%;
}

.carousel__item[data-carousel-item-current],
.carousel__item[data-carousel-item-next],
.carousel__item[data-carousel-item-previous] {
	display: block;
}

.carousel__item[data-carousel-item-current] {
	opacity: 1;
	z-index: 2;
}

.carousel__item[data-carousel-item-next],
.carousel__item[data-carousel-item-previous] {
	z-index: 1;
}

.carousel__item img {
	width: 100%;
	height: auto;
}
/* #endregion */
