.accordion {
}

/* #region Group */
.accordion__group {
	display: block;
	position: relative;
}

/* up-to-medium */
@media (max-width: 1023px) {
	.accordion__group:not(:last-child) {
		margin-bottom: var(--space-48);
	}
}
/* #endregion */

/* #region Group header */
.accordion__group__header {
	margin-right: auto;
	margin-left: auto;
	width: 100%;
}

.accordion__group__header__inner {
	border-bottom: 1px solid var(--color-foreground-alpha-20);
	padding-bottom: var(--space-56);
}

@media (min-width: 1024px) {
	.accordion__group__header {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}

	.accordion__group__header__inner {
		border-top: 1px solid var(--color-foreground-alpha-20);
		border-bottom: 0;
		padding-top: var(--space-24);
		padding-bottom: var(--space-24);
	}
}
/* #endregion */

/* #region Item */
.accordion__item {
	pointer-events: none;
	position: relative;
	z-index: 2;
}
/* #endregion */

/* #region Header + Content */
.accordion__header,
.accordion__panel {
	pointer-events: auto;
}
/* #endregion */

/* #region Header */
.accordion__header {
	margin-bottom: 0;
}
/* #endregion */

/* #region Label + Trigger */
.accordion__label,
.accordion__trigger {
	box-sizing: border-box;
	display: inline-block;
	font-family: inherit;
	font-size: inherit;
	font-style: inherit;
	letter-spacing: inherit;
	line-height: inherit;
	padding: var(--space-16) 0;
	text-align: left;
	width: 100%;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.accordion__label,
	.accordion__trigger {
		display: inline-flex;
		align-items: flex-start;
		justify-items: flex-start;
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.accordion__label,
	.accordion__trigger {
		padding-top: var(--space-24);
		padding-bottom: var(--space-24);
	}
}
/* #endregion */

/* #region Title + Sub-title */
.accordion__label__title,
.accordion__trigger__title {
	display: inline-block;
	margin-bottom: 0;
	width: 100%;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.accordion__label__title,
	.accordion__trigger__title {
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: var(--column-gap-from-small);
		width: calc((100% - var(--column-gap-from-small)) / 2);
	}

	.accordion__label__sub-title,
	.accordion__trigger__sub-title {
		flex-grow: 1;
	}

	.accordion__trigger__sub-title {
		margin-right: 20px; /* Room for trigger icon. */
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.accordion__label__title,
	.accordion__trigger__title {
		margin-right: var(--column-gap-from-medium);
		width: calc((100% - var(--column-gap-from-medium)) / 2);
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.accordion__label__title,
	.accordion__trigger__title {
		margin-right: var(--column-gap-from-wide);
		width: calc((100% - var(--column-gap-from-wide)) / 2);
	}
}
/* #endregion */

/* #region Trigger */
.accordion__trigger {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	color: var(--color-foreground);
	cursor: pointer;
	pointer-events: auto;
}

.accordion__trigger * {
	pointer-events: none;
}

.accordion__trigger:focus:not(:focus-visible) {
	outline: 0;
}

/* #endregion */

/* #region Trigger Icons */
.accordion__trigger__icon {
	display: none;
	position: absolute;
	top: 1.25rem;
	right: 0;
	width: 16px;
	height: 16px;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.accordion__trigger__icon {
		top: 1.875rem;
	}
}

html:not(.no-js):not(.js-loading-error) .accordion__trigger[aria-expanded="false"] .accordion__trigger__icon--plus {
	display: block;
}

html:not(.no-js):not(.js-loading-error) .accordion__trigger[aria-expanded="true"] .accordion__trigger__icon--minus {
	display: block;
}

html:not(.no-js):not(.js-loading-error) .accordion__trigger[aria-expanded="true"] .accordion__trigger__icon--plus {
	display: none;
}

.accordion__trigger__icon .icon {
	display: block;
	width: 100%;
	height: 100%;
}
/* #endregion */

/* #region Pseudo top border */
.accordion__label::before,
.accordion__trigger::before {
	background-color: var(--color-foreground-alpha-20);
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 1px;
}

@media (min-width: 1024px) {
	.accordion__label::before,
	.accordion__trigger::before {
		width: calc((var(--column-width-from-medium) * 8) + (var(--column-gap-from-medium) * 7));
	}
}

@media (min-width: 1280px) {
	.accordion__label::before,
	.accordion__trigger::before {
		width: calc((var(--column-width-from-wide) * 8) + (var(--column-gap-from-wide) * 7));
	}
}

html:not(.no-js):not(.js-loading-error) .accordion__trigger[aria-expanded="true"]::before,
.accordion__trigger:hover::before {
	background-color: var(--color-foreground);
	width: 100%;
}

.accordion__item:first-child .accordion__label::before,
.accordion__item:first-child .accordion__trigger[aria-expanded="false"]:not(:hover)::before {
	content: none;
	display: none;
}
/* #endregion */

/* #region Panel */
html:not(.no-js):not(.js-loading-error) .accordion__panel {
	animation:
		ACCORDION_SLIDE_UP 480ms forwards 1 cubic-bezier(0.375, 0, 0.2, 1),
		ACCORDION_FADE_IN 160ms 112ms forwards 1 linear;
	opacity: 0;
	padding-top: var(--space-16);
	padding-bottom: var(--space-48);
	transform: translateY(0.3125rem);
}

html:not(.no-js, .js-loading-error) .accordion__panel[hidden] {
	display: none;
}

@supports (content-visibility: hidden) {
	html:not(.no-js, .js-loading-error) .accordion__panel[hidden="until-found"] {
		content-visibility: hidden;
		display: block;
		padding: 0;
	}
}

.accordion__panel__inner {
	max-width: 614px;
}

@media (min-width: 1280px) and (hover: hover) {
	html:not(.no-js):not(.js-loading-error) .accordion__item--has-thumbnail .accordion__panel:not([hidden]) {
		min-height: 133px; /* Make sure thumbnail can fit. */
	}
}

@media (min-width: 1366px) and (hover: hover) {
	html:not(.no-js):not(.js-loading-error) .accordion__item--has-thumbnail .accordion__panel:not([hidden]) {
		min-height: 146px; /* Make sure thumbnail can fit. */
	}
}

@media (min-width: 1440px) and (hover: hover) {
	html:not(.no-js):not(.js-loading-error) .accordion__item--has-thumbnail .accordion__panel:not([hidden]) {
		min-height: 160px; /* Make sure thumbnail can fit. */
	}
}
/* #endregion */

/* #region Thumbnail */
.accordion__thumbnail {
	display: none;
}

@media (min-width: 1280px) and (hover: hover) {
	.accordion__thumbnail {
		position: absolute;
		top: 1.875rem;
		left: 0;
		width: 320px;
		height: auto;
	}

	html:not(.no-js):not(.js-loading-error) .accordion__panel:not([hidden]) ~ .accordion__thumbnail,
	.accordion__header:hover ~ .accordion__thumbnail {
		display: block;
	}
}

@media (min-width: 1366px) and (hover: hover) {
	.accordion__thumbnail {
		width: 340px;
	}
}

@media (min-width: 1440px) and (hover: hover) {
	.accordion__thumbnail {
		width: 360px;
	}
}
/* #endregion */

/* #region Keyframe animations */
@keyframes ACCORDION_FADE_IN {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes ACCORDION_SLIDE_UP {
	0% {
		transform: translateY(0.3125rem);
	}

	100% {
		transform: translateY(0);
	}
}
/* #endregion */
