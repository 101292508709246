.post-intro {
}

/* #region Meta. */
@media (min-width: 1024px) {
	.post-intro__meta {
		padding-top: 0.125rem; /* Nudge meta down so it's visually aligned with the h1 next to it. */
	}
}

@media (min-width: 1280px) {
	.post-intro__meta {
		padding-top: 0.375rem; /* Nudge meta down so it's visually aligned with the h1 next to it. */
	}
}
/* #endregion. */

/* #region Title. */
.post-intro__title {
	margin-top: 0;
}
/* #endregion. */

/* #region Excerpt. */
.post-intro__excerpt {
	margin-top: 6.25rem; /* 100px */
}

@media (min-width: 1024px) {
	.post-intro__excerpt {
		margin-top: 9.375rem; /* 150px */
	}
}

@media (min-width: 1440px) {
	.post-intro__excerpt {
		margin-top: 15.625rem; /* 250px */
	}
}
/* #endregion. */
