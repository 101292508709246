/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
/* #region Site Header : Theme */

/*
	Conditions:
		- Target must not be legacy
		- JS bundle must be loading or loaded (without errors)

	States:
		- black
		- site
*/

html:not(.js-loading-error) body[site-header-theme-on-load="black"]:not([legacy="true"]) .site-header {
	background-color: var(--color-black);
	color: var(--color-white);
}

html:not(.js-loading-error) body[site-header-theme-on-load="site"]:not([legacy="true"]) .site-header {
	background-color: var(--color-background);
	color: var(--color-foreground);
}

html:not(.js-loading-error):not(.no-js) body[site-header-theme="black"][site-header-observed-element-is-visible]:not([legacy="true"]) .site-header {
	background-color: var(--color-black);
	color: var(--color-white);
}

html:not(.js-loading-error):not(.no-js) body[site-header-theme="site"][site-header-observed-element-is-visible]:not([legacy="true"]) .site-header {
	background-color: var(--color-background);
	color: var(--color-foreground);
}

/* #endregion Site Header : Theme */

/* #region Site Header : Transparency */

/*
	Conditions:
		- Target must not be legacy
		- JS bundle must be loading or loaded (without errors)

	States:
		- true
		- false
*/

html:not(.js-loading-error) body[site-header-transparent-on-load="true"]:not([legacy="true"]) .site-header {
	background-color: transparent;
}

html:not(.js-loading-error) body[site-header-transparent-on-load="true"]:not([legacy="true"]) .site-header {
	background-color: transparent;
}

html:not(.js-loading-error):not(.no-js) body[site-header-transparent="true"][site-header-observed-element-is-visible]:not([legacy="true"]) .site-header {
	background-color: transparent;
}

html:not(.js-loading-error):not(.no-js) body[site-header-transparent="true"][site-header-observed-element-is-visible]:not([legacy="true"]) .site-header {
	background-color: transparent;
}

/* #endregion Site Header : Transparency */

/* #region Subnav : Hide */
html:not(.js-loading-error) body[site-header-transparent-on-load="true"]:not([legacy="true"]) .sub-navigation-menu {
	opacity: 0;
	pointer-events: none;
}

html:not(.js-loading-error) body[site-header-transparent-on-load="true"]:not([legacy="true"]) .sub-navigation-menu {
	opacity: 0;
	pointer-events: none;
}

html:not(.js-loading-error):not(.no-js) body[site-header-transparent="true"][site-header-observed-element-is-visible]:not([legacy="true"]) .sub-navigation-menu {
	opacity: 0;
	pointer-events: none;
}

html:not(.js-loading-error):not(.no-js) body[site-header-transparent="true"][site-header-observed-element-is-visible]:not([legacy="true"]) .sub-navigation-menu {
	opacity: 0;
	pointer-events: none;
}
/* #endregion Subnav : Hide */

/* #region Language Switch : Hide */

/* up-to-medium */
@media (max-width: 1023px) {
	html:not(.js-loading-error) body[site-header-transparent-on-load="true"]:not([legacy="true"]) .language-switcher {
		opacity: 0;
		pointer-events: none;
	}

	html:not(.js-loading-error) body[site-header-transparent-on-load="true"]:not([legacy="true"]) .language-switcher {
		opacity: 0;
		pointer-events: none;
	}

	html:not(.js-loading-error):not(.no-js) body[site-header-transparent="true"][site-header-observed-element-is-visible]:not([legacy="true"]) .language-switcher {
		opacity: 0;
		pointer-events: none;
	}

	html:not(.js-loading-error):not(.no-js) body[site-header-transparent="true"][site-header-observed-element-is-visible]:not([legacy="true"]) .language-switcher {
		opacity: 0;
		pointer-events: none;
	}
}
/* #endregion Language Switch : Hide */
